import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";

const WithHeaderLayout = ({
  children,
  currUser,
  isAuthenticated,
  handleLogout,
  history,
}) => {
  return (
    <React.Fragment>
      <Nav
        currUser={currUser}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
        history={history}
      />
      <div
        style={{
          minHeight: "100%",
          position: "relative",
        }}
      >
        {children}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default WithHeaderLayout;
