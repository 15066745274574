import TEAMS from "../../constants/Teams"

export const getTeamName = (homeTeamSlug) => {
    const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
    if (team) {
        return team.name;
    }

    return null;
}
export const getTeamColor = (homeTeamSlug) => {
    const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
    if (team) {
        return team.colors;
    }

    return null;
}