import validator from "validator";
import { API } from "aws-amplify";

export const isValidName = (name) => {
  return name && name !== "";
};

export const isValidEmail = (email) => {
  return validator.isEmail(email);
};

export const isValidMobileNo = async (mobileNo) => {
  if (mobileNo && mobileNo !== "") {
    try {
      let isVerifiedMobileNo = await API.post("v2", `marketplace/accounts/verify-seller-phone`, {
        body: {
            phone_number: `+1${mobileNo}`
        },
      });
      const isValidMobileNo = validator.isMobilePhone(mobileNo, ["en-CA"]) && isVerifiedMobileNo;
      return isValidMobileNo;
    } catch (error) { }
  }
  return false;
};