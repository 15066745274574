import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { loadReCaptcha } from "react-recaptcha-google";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Routes from "./routes";

import "typeface-roboto";
import "./App.css";
import createHistory from "history/createBrowserHistory";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});
/*
export const history = createHistory();
history.listen((location, action) => {
  if (location.hash.slice(0, 2) === "#s") {
    window.scrollTo(window.scrollX, window.scrollY);
  }
});*/

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      userNeedNewPassword: null,
      isAuthenticated: false,
      isAuthenticating: true,
      isEmergencyMode: false,
    };
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUserNeedNewPassword = (userNeedNewPassword) => {
    this.setState({ userNeedNewPassword });
  };

  setUserAttributes = ({ attributes }) => {
    this.setState({
      user: attributes,
    });
  };

  handleLogout = async (event) => {
    await Auth.signOut();

    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  };

  async componentDidMount() {
    loadReCaptcha();
    try {
      await Auth.currentSession();

      this.userHasAuthenticated(true);

      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.setUserAttributes(user);
      this.setState({
        isAuthenticating: false,
      });
    } catch (e) {
      this.setState({
        isAuthenticating: false,
      });
    }
    

    const status = await this.status().catch((e) => {console.error(e)});

    this.setState({
      isEmergencyMode: status,
    });
  }

  status = () => {
    return API.get("v2", `marketplace/emergencyMode/get-emergency-mode`);
  };

  componentDidUpdate(prevProps, prevState) {
    (prevState.isAuthenticating !== this.state.isAuthenticating) && window.scrollTo(0, 0);
    if (prevState.isEmergencyMode !== this.state.isEmergencyMode) {
      if (
        this.state.isEmergencyMode == "True" &&
        window.location.pathname != "/under-maintenance"
      ) {
        window.location.replace("/under-maintenance");
      } else if (
        this.state.isEmergencyMode == "False" &&
        window.location.pathname == "/under-maintenance"
      ) {
        window.location.replace("/");
      }
    }
  }

  render() {
    const { classes } = this.props;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUserAttributes: this.setUserAttributes,
      currUser: this.state.user,
      handleLogout: this.handleLogout,
      history: this.props.history,
      userNeedNewPassword: this.state.userNeedNewPassword,
      setUserNeedNewPassword: this.setUserNeedNewPassword,
    };

    return (
      !this.state.isAuthenticating && (
        <React.Fragment>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "WebSite",
              name: "FANSFIRST",
              url: "https://www.fansfirst.ca/",
              potentialAction: {
                "@type": "SearchAction",
                target:
                  "https://www.fansfirst.ca/search?q={search_term_string}",
                "query-input": "required name=search_term_string",
              },
            })}
          </script>

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "FANSFIRST",
              url: "https://www.fansfirst.ca/",
              logo: "https://www.fansfirst.ca/static/media/logo.165f4bb7.png",
              sameAs: [
                "https://www.facebook.com/fansfirst.ca",
                "https://www.instagram.com/fansfirst.ca",
                "https://www.twitter.com/FansFirstTix",
              ],
            })}
          </script>

          <CssBaseline />
          <div id="App" className={classes.root}>
            <Routes childProps={childProps} />
          </div>
        </React.Fragment>
      )
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withRouter)(App);
