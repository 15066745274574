import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import { Grid, Typography } from "@material-ui/core";
import Banners from '../constants/Banners';

import { styles as homeStyles } from "./styles";
import GamesList from "../components/games-list";
import { cflTeamCheck, getLocalizedDateTime, getStaticTeID, getTeamId, getTEPlayoffID, includeTeListingsCheck, rateFallback } from "../libs/shared/seat-helpers";
import { getTeamZone } from "../libs/shared/get-team-zone";
import { getTeamName } from "../libs/shared/team-helper";
import { grabJaysLowestFromTE, grabLeafsRapsLowerTE, grabLowestCFLFromTE, grabLowestFromTE, grabLowestMLSFromTE } from "../libs/shared/grid-helper";
import SearchAutocomplete from "../components/search-auto-complete";

const styles = (theme) => homeStyles(theme);

class Games extends Component {
  constructor(props) {
    super();

    const url = window.location.pathname;
    const selectedUrl = url.split("/")[1];
    const storedRate = localStorage.getItem("currencyRate");
    const fxRateInit = storedRate ? JSON.parse(storedRate) : { timestamp: 1670452262, rate: rateFallback };

    this.state = {
      selectedTeam: selectedUrl,
      games: {},
      isLoadingGames: true,
      lowestPrice: 0,
      bannerDesktop: null,
      bannerLarge: null,
      bannerXLarge: null,
      teLowestParams: [],
      currencyRate: fxRateInit,
      teListings: [],
      teIdList: [],
      teMinPrices: {}
    };

    this.onTeamSelection = this.onTeamSelection.bind(this);
  }

  async componentDidMount() {
    const { selectedTeam } = this.state;

    const _bannerDefault = Banners["default"][selectedTeam];
    const _bannerDesktop = Banners["desktop"][selectedTeam];
    const _bannerLarge = Banners["large"][selectedTeam];
    const _bannerXLarge = Banners["xlarge"][selectedTeam];
    this.setState({
      bannerDefault: _bannerDefault,
      bannerDesktop: _bannerDesktop,
      bannerLarge: _bannerLarge,
      bannerXLarge: _bannerXLarge,
    });
    this.loadGames({ homeTeamSlug: selectedTeam });
    this._getCurrencyExchangeRate();
  }

  onTeamSelection = async ({ homeTeamSlug }) => {
    this.setState({ selectedTeam: homeTeamSlug, isLoadingGames: true });

    await this.loadGames({ homeTeamSlug });
  };

  loadGames = async ({ homeTeamSlug }) => {
    const { teLowestParams, teListings, games } = this.state;
    const currGames = { ...games };
    const tempNewLowestParams = [...teLowestParams];
    const url = window.location.href;
    let isDevelopmentServer = false;
    if (url.includes("development") || url.includes("localhost") || url.includes("coupons")) {
      isDevelopmentServer = true;
    }
    if (!currGames[homeTeamSlug]) {
      const homeGames = await this.games({ homeTeamSlug });
      tempNewLowestParams[homeTeamSlug] = [];
      homeGames.forEach((game) => {
        if (includeTeListingsCheck(game)) {
          tempNewLowestParams[homeTeamSlug].push({
            id: game.id,
            homeTeamSlug,
            gameDate: game.date,
            timezone: game.timezone,
            isPlayoffs: game.isPlayoffs,
            playoffSequence: game.playoffSequence,
          });
        }
      });
      if (isDevelopmentServer) {
        var tbdGames = homeGames.filter((game) => game.isTbd && !game.isArchived).sort((a, b) => a.playoffSequence - b.playoffSequence);
        var regulargames = homeGames.filter((game) => !game.isTbd && !game.isArchived && moment.now() <= moment(game.date).add(1, "hour").valueOf()).sort((a, b) => a.date - b.date);
      } else {
        var tbdGames = homeGames.filter((game) => game.isTbd && !game.isArchived && !game.testGame).sort((a, b) => a.playoffSequence - b.playoffSequence);
        var regulargames = homeGames.filter((game) => !game.isTbd && !game.isArchived && !game.testGame && moment.now() <= moment(game.date).add(1, "hour").valueOf()).sort((a, b) => a.date - b.date);
      }
      currGames[homeTeamSlug] = [...regulargames, ...tbdGames];

      this.setState({
        games: currGames,
        isLoadingGames: tempNewLowestParams[homeTeamSlug] && tempNewLowestParams[homeTeamSlug].length > 0,
        teLowestParams: tempNewLowestParams,
      });
      tempNewLowestParams[homeTeamSlug] && tempNewLowestParams[homeTeamSlug].length > 0 && !teListings[homeTeamSlug] && (await this._handleTETeamFetch());
    } else {
      this.setState({ isLoadingGames: false, selectedTeam: homeTeamSlug });
    }
  };

  games = async ({ homeTeamSlug }) => {
    return API.get("v2", `marketplace/events/homepage/by/homeTeamSlug/${homeTeamSlug}?includeMinPrices=true`);
  };

  renderBanner() {
    const { classes } = this.props;
    const { selectedTeam, bannerDefault } = this.state;
    const teamName = getTeamName(selectedTeam);

    return (
      <div className={classes.bannerContainer} style={{ backgroundImage: `url(${bannerDefault})` }}>
        <Grid
          md={12}
          className={classes.bannerTitleContainer}
        >
          <Typography
            color="primary"
            className={classes.bannerTitle}
          >
            {teamName} Tickets
          </Typography>
          <div className={classes.bannerSearchContainer}>
            <SearchAutocomplete />
          </div>
        </Grid>
      </div>
    );
  }

  render() {
    const { selectedTeam, games, isLoadingGames } = this.state;

    let headline = '';
    let metaTitle = '';
    let metaDescription = '';
    if (selectedTeam === 'calgary-flames') {
      headline = 'Calgary Flames Tickets';
      metaTitle = 'Calgary Flames Tickets | fansfirst.ca | fansfirst.ca';
      metaDescription = 'Buy or sell Calgary Flames tickets at the best price on fansfirst.ca. Discover the best seats in the Saddledome with great ticket prices from legit season ticket holders with our seating chart. Buy Now!';
    } else if (selectedTeam === 'edmonton-oilers') {
      headline = 'Edmonton Oilers Tickets';
      metaTitle = 'Edmonton Oilers Tickets - Oilers Games | fansfirst.ca';
      metaDescription = 'Get great deals on Edmonton Oilers tickets from fansfirst.ca! Find the best prices for home game tickets at Rogers Place from season ticket holders, see the Edmonton Oilers schedule, and cheer the Oilers on! Click above to get started!';
    } else if (selectedTeam === 'vancouver-canucks') {
      headline = 'Vancouver Canucks Tickets';
      metaTitle = 'Buy Vancouver Canucks Tickets | No Extra Fees or Markup';
      metaDescription = 'Buy legit Vancouver Canucks tickets without any service charges at fansfirst.ca! We make it easier for you to get the best seats in Rogers Arena from season ticket holders. See Vancouver Canucks tickets prices, schedules, and more.';
    } else if (selectedTeam === 'winnipeg-jets') {
      headline = 'Winnipeg Jets Tickets';
      metaTitle = 'Buy Winnipeg Jets Tickets | NHL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Winnipeg Jets tickets from season ticket holders at the best price and enjoy a live game at Canada Life Centre without any fees or markup. To see Winnipeg Jets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'toronto-raptors') {
      headline = 'Toronto Raptors Tickets';
      metaTitle = 'Toronto Raptors Games - Buy and Sell Tickets Online | Toronto Blue Jays tickets';
      metaDescription = 'Buy/sell Toronto raptors games and blue jays tickets online with FansFirst. We offer the best deals and a hassle-free ticket-buying and selling experience. Visit our website today!';
    } else if (selectedTeam === 'ottawa-senators') {
      headline = 'Ottawa Senators Tickets';
      metaTitle = 'Ottawa Senators Games - Buy and Sell Tickets Online | FansFirst';
      metaDescription = 'FansFirst lets you buy and sell Ottawa senators game tickets online. We provide the best prices and a hassle-free experience while buying and selling tickets. Go to our website right away!';
    } else if (selectedTeam === 'toronto-maple-leafs') {
      headline = 'Toronto Maple Tickets';
      metaTitle = 'Toronto Maple Leafs Games - Buy Tickets Online on FansFirst';
      metaDescription = 'You can purchase and sell Toronto Maple Leafs game tickets online easily and without hassle through FansFirst. Take advantage of our great deals by visiting our website today!';
    } else if (selectedTeam === 'toronto-blue-jays') {
      headline = 'Toronto Blue Jays Tickets';
      metaTitle = 'Buy Toronto Blue Jays Tickets | MBL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Toronto Blue Jays tickets from season ticket holders at the best price and enjoy a live game at Rogers Centre without any fees or markup. To see Toronto Blue Jays ticket prices and schedules, click here.';
    } else if (selectedTeam === 'edmonton-elks') {
      headline = 'Edmonton Elks Tickets';
      metaTitle = 'Buy Edmonton Elks Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Edmonton Elks tickets from season ticket holders at the best price and enjoy a live game at Commonwealth Stadium without any fees or markup. To see Edmonton Elks ticket prices and schedules, click here.';
    } else if (selectedTeam === 'winnipeg-blue-bombers') {
      headline = 'Winnipeg Blue Bombers Tickets';
      metaTitle = 'Buy Winnipeg Blue Bombers Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Winnipeg Blue Bombers tickets from season ticket holders at the best price and enjoy a live game at IG Field without any fees or markup. To see Winnipeg Blue Bombers ticket prices and schedules, click here.';
    } else if (selectedTeam === 'toronto-argonauts') {
      headline = 'Toronto Argonauts Tickets';
      metaTitle = 'Buy Toronto Argonauts Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Toronto Argonauts tickets from season ticket holders at the best price and enjoy a live game at BMO Field without any fees or markup. To see Toronto Argonauts ticket prices and schedules, click here.';
    } else if (selectedTeam === 'calgary-stampeders') {
      headline = 'Calgary Stampeders Tickets';
      metaTitle = 'Buy Calgary Stampeders Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Calgary Stampeders tickets from season ticket holders at the best price and enjoy a live game at McMahon Stadium without any fees or markup. To see Calgary Stampeders ticket prices and schedules, click here.';
    } else if (selectedTeam === 'ottawa-redblacks') {
      headline = 'Ottawa Redblacks Tickets';
      metaTitle = 'Buy Ottawa Redblacks Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Ottawa Redblacks tickets from season ticket holders at the best price and enjoy a live game at TD Place without any fees or markup. To see Ottawa Redblacks ticket prices and schedules, click here.';
    } else if (selectedTeam === 'bc-lions') {
      headline = 'BC Lions Tickets';
      metaTitle = 'Buy BC Lions Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your BC Lions tickets from season ticket holders at the best price and enjoy a live game at BC Place without any fees or markup. To see BC Lions ticket prices and schedules, click here.';
    } else if (selectedTeam === 'saskatchewan-roughriders') {
      headline = 'Saskatchewan Roughriders Tickets';
      metaTitle = 'Buy Saskatchewan Roughriders Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Saskatchewan Roughriders tickets from season ticket holders at the best price and enjoy a live game at Mosaic Stadium without any fees or markup. To see Saskatchewan Roughriders ticket prices and schedules, click here.';
    } else if (selectedTeam === 'hamilton-tigercats') {
      headline = 'Hamilton Tigercats Tickets';
      metaTitle = 'Buy Hamilton Tigercats Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Tigercats tickets from season ticket holders at the best price and enjoy a live game at Tim Hortons Field Stadium without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'grey-cup') {
      headline = 'Grey Cup Tickets';
      metaTitle = 'Buy Grey Cup Tickets | CFL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Grey Cup tickets from season ticket holders at the best price and enjoy a live game at Tim Hortons Field Stadium without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'calgary-wranglers') {
      headline = 'Calgary Wranglers Tickets';
      metaTitle = 'Buy Calgary Wranglers Tickets | AHL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Calgary Wranglers tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'nhl-special-games') {
      headline = 'All Star Game Tickets';
      metaTitle = 'All Star Game - Buy Tickets Online on FansFirst';
      metaDescription = 'You can purchase and sell All Star Game game tickets online easily and without hassle through FansFirst. Take advantage of our great deals by visiting our website today!';
    } else if (selectedTeam === 'manitoba-moose') {
      headline = 'Manitoba Moose Tickets';
      metaTitle = 'Buy Manitoba Moose Tickets | AHL Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Manitoba Moose tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'vancouver-whitecaps-fc') {
      headline = 'Vancouver Whitecaps FC Tickets';
      metaTitle = 'Buy Vancouver Whitecaps FC Tickets | MLS Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Vancouver Whitecaps FC tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'toronto-fc') {
      headline = 'Toronto FC Tickets';
      metaTitle = 'Buy Toronto FC Tickets | MLS Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Toronto FC tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    } else if (selectedTeam === 'abbotsford-canucks') {
      headline = 'Abbotsford Canucks Tickets';
      metaTitle = 'Buy Abbotsford Canucks Tickets | MLS Tickets & Schedule | fansfirst.ca';
      metaDescription = 'Buy your Abbotsford Canucks tickets from season ticket holders at the best price and enjoy a live game at Abbotsford Centre without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.';
    }
    const currentURL = window.location.href;

    let minPrice = null;

    if (selectedTeam && games[selectedTeam]) {
      if (games[selectedTeam].length === 0) {
        return (
          <React.Fragment>
            <Helmet>
              <title>{metaTitle}</title>
              <meta name="description" content={metaDescription} />
              <meta name="keywords" content={`games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets, ${headline}`} />
              <meta name="robots" content="index, follow" />
              <meta property="og:title" content={metaTitle} />
              <meta property="og:description" content={metaDescription} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={currentURL} />
              <link rel="canonical" href={currentURL} />
            </Helmet>
            {this.renderBanner()}
            <Typography variant="headline" style={{display: 'none'}}>
              {headline}
            </Typography>
            <GamesList selectedTeam={selectedTeam} onTeamSelection={this.onTeamSelection} games={games} isLoadingGames={isLoadingGames} teIdList={this.state.teIdList[selectedTeam] || []} />
          </React.Fragment>
        );
      }
      minPrice = games[selectedTeam].reduce((prev, curr) => {
        const { minPrices: prevMinPrices } = prev;
        const { minPrices: currMinPrices } = curr;

        return prevMinPrices.pressLevelMinPrice < currMinPrices.pressLevelMinPrice ? prev : curr;
      }).minPrices.pressLevelMinPrice;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={`games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets, ${headline}`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderBanner()}
        <Typography variant="headline" style={{display: 'none'}}>
          {headline}
        </Typography>
        <GamesList selectedTeam={selectedTeam} onTeamSelection={this.onTeamSelection} games={games} isLoadingGames={isLoadingGames} teIdList={this.state.teIdList[selectedTeam] || []} />
      </React.Fragment>
    );
  }
  _handleTETeamFetch = async () => {
    const { selectedTeam: teamSlug } = this.state;
    this.setState({ ...this.state, isLoadingGames: true });
    const teamId = getTeamId(teamSlug);

    if (teamId === "") {
      this.setState({ ...this.state, isLoadingGames: false });
      return;
    }
    await API.get("v2", `marketplace/ticketEvo/events/index/by/performerId/${teamId}`)
      .then((data) => {
        const { current_page, per_page, total_entries, events } = data;
        this.setState({
          ...this.state,
          teEvents: events,
        });
        this._handleTEGameFetch(events);
      })
      .catch((e) => {
        this.setState({ ...this.state, isLoadingGames: false });
        console.error(e);
      });
  };
  _getCurrencyExchangeRate = async () => {
    await API.get("v2", `marketplace/ticketEvo/currencyRate`)
      .then((data) => {
        this.setState({ ...this.state, currencyRate: data.currencyRate });
        localStorage.setItem("currencyRate", JSON.stringify(data.currencyRate));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  _formatTEZoneToFF = (teSeat) => {
    const { selectedTeam: teamSlug } = this.state;
    const zoneInvetory = teSeat.tevo_section_name.split(" ").slice(0, -1).join(" ");
    const zoneNo = teSeat.tevo_section_name.split(" ").pop();
    return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
  };

  _handleTEGameFetch = async (events) => {
    const { teLowestParams, selectedTeam, teListings, games, currencyRate } = this.state;
    const currentLowestParams = teLowestParams[selectedTeam] ? teLowestParams[selectedTeam] : [];
    const teIdList = this.state.teIdList;
    teIdList[selectedTeam] = [];
    this.setState({ 
      teMinPrices: {
        ...this.state.teMinPrices,
        [selectedTeam]: []
      }
    });
    currentLowestParams.forEach(async (lowestParams, lowIndex) => {
      const { gameDate: date, timezone, isPlayoffs, playoffSequence } = lowestParams;
      const gameDate = getLocalizedDateTime(date, timezone).stringDate;

      const findGameXTE = events.find((teGame) => {
        const teGameDate = teGame.occurs_at.split("T")[0];
        return gameDate === teGameDate;
      });

      const isStaticIdExist = getStaticTeID({date, timezone, homeTeamSlug: selectedTeam});
      if (!findGameXTE && !isPlayoffs && isStaticIdExist === '') {
        this.setState({ ...this.state, isLoadingGames: false });
        return;
      }

      if (findGameXTE && findGameXTE.id && !isPlayoffs) {
        const idItem = {};
        idItem[gameDate] = findGameXTE.id;
        teIdList[selectedTeam].push(idItem);
      }
      if (isStaticIdExist !== '') {
        const idItem = {};
        idItem[gameDate] = isStaticIdExist;
        teIdList[selectedTeam].push(idItem);
      }
      if (isPlayoffs) {
        const idItem = {};
        idItem[playoffSequence] = getTEPlayoffID(selectedTeam, playoffSequence);
        teIdList[selectedTeam].push(idItem);
      }

      let teIdToFetch = '';
      if (findGameXTE || isPlayoffs) {
        teIdToFetch = isPlayoffs ? getTEPlayoffID(selectedTeam, playoffSequence) : findGameXTE.id;
      }
      
      const finalTEIdToFetch =  isStaticIdExist === '' ? teIdToFetch : isStaticIdExist;

      const teListingObject = await API.get("v2", `marketplace/ticketEvo/listings/index/by/eventId/${finalTEIdToFetch}?order_by=retail_price+ASC`).catch((e) => {
        this.setState({ ...this.state, isLoadingGames: false });
        console.error(e);
      });
      const currentGame = {date: lowestParams.gameDate, homeTeamSlug: selectedTeam, timezone: lowestParams.timezone};
      const ticket_groups = teListingObject && teListingObject.ticket_groups ? teListingObject.ticket_groups : [];
      const cflTeamList = cflTeamCheck(selectedTeam)
        ? grabLowestCFLFromTE(
            ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          )
        : grabLowestFromTE(
            ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          );
      let leafRaps =
        selectedTeam === "toronto-maple-leafs" || selectedTeam === "toronto-raptors"
          ? grabLeafsRapsLowerTE(
              ticket_groups,
              lowestParams,
              currencyRate,
              selectedTeam,
              currentGame
            )
          : cflTeamList;
      let mlsTeamList =
      selectedTeam === "vancouver-whitecaps-fc" || selectedTeam === "toronto-fc"
        ? grabLowestMLSFromTE(
            ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          )
        : leafRaps;
      let seatList =
        selectedTeam === "toronto-blue-jays"
          ? grabJaysLowestFromTE(
              ticket_groups,
              lowestParams,
              currencyRate,
              selectedTeam,
              currentGame
            )
          : mlsTeamList;

      const upPrice = seatList.find(seat => "up" in seat);
      const midPrice = seatList.find(seat => "mid" in seat);
      const lowPrice = seatList.find(seat => "low" in seat);
      const currentTEMinPrice = {
        gameId: lowestParams.id,
        pressLevelMinPrice: upPrice ? upPrice["up"].price : null
      };
      if (selectedTeam === "toronto-maple-leafs" || selectedTeam === "toronto-raptors") {
        currentTEMinPrice["secondLevelMinPrice"] = lowPrice ? lowPrice["low"].price : null;
        currentTEMinPrice["lowerBowlMinPrice"] = null;
      } else {
        currentTEMinPrice["secondLevelMinPrice"] = midPrice ? midPrice["mid"].price : null;
        currentTEMinPrice["lowerBowlMinPrice"] = lowPrice ? lowPrice["low"].price : null;            
      }
      this.setState({ 
        teMinPrices: {
          ...this.state.teMinPrices,
          [selectedTeam]: [
            ...this.state.teMinPrices[selectedTeam],
            currentTEMinPrice
          ]
        }
      });

      if (seatList.length > 0) {
        seatList.forEach((seat, index) => {
          if (games[selectedTeam] && teLowestParams[selectedTeam]) {
            games[selectedTeam].forEach((game, key) => {
              if (game.id === teLowestParams[selectedTeam][lowIndex].id) {
                if (
                  !cflTeamCheck(selectedTeam) &&
                  selectedTeam !== "ottawa-senators" &&
                  selectedTeam !== "calgary-flames" &&
                  selectedTeam !== "toronto-raptors" &&
                  selectedTeam !== "winnipeg-jets" &&
                  selectedTeam !== "vancouver-canucks" &&
                  selectedTeam !== "toronto-blue-jays" &&
                  selectedTeam !== "manitoba-moose" &&
                  selectedTeam !== "toronto-maple-leafs"
                ) {
                  // this._storeLowestPrice(game.id, seat.price);
                  if (
                    game.minPrices.pressLevelMinPrice > seat.price ||
                    !game.minPrices.pressLevelMinPrice
                  ) {
                    games[selectedTeam][key]["minPrices"][
                      "pressLevelMinPrice"
                    ] = Number(seat.price);
                    this.setState({ ...this.state, games: games });
                  }
                }
                if (selectedTeam === 'bc-lions') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === 'vancouver-whitecaps-fc') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "calgary-flames") {
                  const lowData = seat['low'];
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "calgary-stampeders") {
                  // this._storeLowestPrice(game.id, seat.price);
                  if (game.minPrices.lowerBowlMinPrice > seat.price || !game.minPrices.lowerBowlMinPrice) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(seat.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "winnipeg-jets") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "manitoba-moose") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "edmonton-elks") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "vancouver-canucks") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "winnipeg-blue-bombers") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "toronto-argonauts") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "toronto-fc") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "ottawa-senators") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === 'ottawa-redblacks') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  // if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                  //   games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                  //   this.setState({...this.state, games: games});
                  // }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "toronto-blue-jays") {
                  const lowData = seat["low"];
                  const midData = seat["mid"];
                  const upData = seat["up"];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({ ...this.state, games: games });
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({ ...this.state, games: games });
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({ ...this.state, games: games });
                  }
                }
                if (selectedTeam === 'hamilton-tigercats') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === 'grey-cup') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === 'saskatchewan-roughriders') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: games});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: games});
                  }
                }
                if (selectedTeam === "toronto-maple-leafs") {
                  const lowData = seat['low'];
                  const upData = seat["up"];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({ ...this.state, games: games });
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({ ...this.state, games: games });
                  }
                }
                if (selectedTeam === "toronto-raptors") {
                  const lowData = seat['low'];
                  const upData = seat["up"];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({ ...this.state, games: games });
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    games[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({ ...this.state, games: games });
                  }
                }
              }
            });
          }
        });
      }
    });
    this.setState({
      ...this.state,
      teIdList: teIdList,
      isLoadingGames: false,
    });
  };

  _getStoredLowest = async (gameId, games) => {
    const { teLowestParams, selectedTeam, teListings } = this.state;
    const newGames = games;
    await API.get("v2", `marketplace/ticketEvo/getLowestPrice?gameId=${gameId}`).then((data) => {
      if (newGames[selectedTeam]) {
        const currentIndex = newGames[selectedTeam].findIndex((game) => game.id === gameId);
        const lowestData = data.length > 0 && data[0];
        const isStoredPriceLower = currentIndex > -1 && newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice > lowestData.press_level_min_price && lowestData;
        const isNewGameExist = currentIndex > -1 && newGames[selectedTeam][currentIndex] && newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice;
        const isUseStored = isStoredPriceLower || !isNewGameExist;
        if (
          isUseStored &&
          currentIndex > -1 &&
          selectedTeam !== "toronto-blue-jays" &&
          selectedTeam !== "toronto-maple-leafs" &&
          !cflTeamCheck(selectedTeam)
        ) {
          newGames[selectedTeam][currentIndex]["minPrices"][
            "pressLevelMinPrice"
          ] = Number(lowestData.press_level_min_price);
          this.setState({ ...this.state, games: newGames });
        }
      }
    });
  };

  _storeLowestPrice = async (teMinPrices) => {
    const data = await API.post("v2", "marketplace/ticketEvo/saveLowestPrice", {
      body: { teMinPrices },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTeam, teMinPrices, teIdList } = this.state;
    const { teamSlug } = this.props.match.params;

    if (teMinPrices[selectedTeam] && prevState.teMinPrices[selectedTeam]) {
      if (teMinPrices[selectedTeam].length > prevState.teMinPrices[selectedTeam].length) {
        if (teMinPrices[selectedTeam].length === teIdList[selectedTeam].length) {
          this._storeLowestPrice(teMinPrices[selectedTeam]);
        }
      }
    }

    // if (JSON.stringify(prevState.teLowestParams[selectedTeam]) !== JSON.stringify(teLowestParams[selectedTeam]) && selectedTeam !== "toronto-blue-jays") {
    //   teLowestParams[selectedTeam] && teLowestParams[selectedTeam].forEach((lowest) => this._getStoredLowest(lowest.id, games));
    // }
    if (prevProps.match.params.teamSlug !== teamSlug) {
      window.location.reload();
    }
  }
}

export default withStyles(styles)(Games);
