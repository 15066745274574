import oilersDefaultBanner from "../images/banners/games/default/oilers.png";
import oilersDesktopBanner from "../images/banners/games/desktop/oilers.png";
import oilersLargeBanner from "../images/banners/games/large/oilers.png";
import oilersXLargeBanner from "../images/banners/games/xlarge/oilers.png";
import flamesDefaultBanner from "../images/banners/games/default/flames.png";
import flamesDesktopBanner from "../images/banners/games/desktop/flames.png";
import flamesLargeBanner from "../images/banners/games/large/flames.png";
import flamesXLargeBanner from "../images/banners/games/xlarge/flames.png";
import jetsDefaultBanner from "../images/banners/games/default/jets.png";
import jetsDesktopBanner from "../images/banners/games/desktop/jets.png";
import jetsLargeBanner from "../images/banners/games/large/jets.png";
import jetsXLargeBanner from "../images/banners/games/xlarge/jets.png";
import canucksDefaultBanner from "../images/banners/games/default/canucks.png";
import canucksDesktopBanner from "../images/banners/games/desktop/canucks.png";
import canucksLargeBanner from "../images/banners/games/large/canucks.png";
import canucksXLargeBanner from "../images/banners/games/xlarge/canucks.png";
import leafsDefaultBanner from "../images/banners/games/default/leafs.png";
import leafsDesktopBanner from "../images/banners/games/desktop/leafs.png";
import leafsLargeBanner from "../images/banners/games/large/leafs.png";
import leafsXLargeBanner from "../images/banners/games/xlarge/leafs.png";
import senatorsDefaultBanner from "../images/banners/games/default/senators.png";
import senatorsDesktopBanner from "../images/banners/games/desktop/senators.png";
import senatorsLargeBanner from "../images/banners/games/large/senators.png";
import senatorsXLargeBanner from "../images/banners/games/xlarge/senators.png";
import raptorsDefaultBanner from "../images/banners/games/default/raptors.png";
import raptorsDesktopBanner from "../images/banners/games/desktop/raptors.png";
import raptorsLargeBanner from "../images/banners/games/large/raptors.png";
import raptorsXLargeBanner from "../images/banners/games/xlarge/raptors.png";
import blueJaysDefaultBanner from "../images/banners/games/default/blueJays.png";
import blueJaysDesktopBanner from "../images/banners/games/desktop/blueJaysNew.png";
import blueJaysLargeBanner from "../images/banners/games/large/blueJays.png";
import blueJaysXLargeBanner from "../images/banners/games/xlarge/blueJays.png";
import elksDefaultBanner from "../images/banners/games/default/elks.png";
import elksDesktopBanner from "../images/banners/games/desktop/elks.png";
import elksLargeBanner from "../images/banners/games/large/elks.png";
import elksXLargeBanner from "../images/banners/games/xlarge/elks.png";
import blueBombersDefaultBanner from "../images/banners/games/default/blueBombers.png";
import blueBombersDesktopBanner from "../images/banners/games/desktop/blueBombers.png";
import blueBombersLargeBanner from "../images/banners/games/large/blueBombers.png";
import blueBombersXLargeBanner from "../images/banners/games/xlarge/blueBombers.png";
import argonautsDefaultBanner from "../images/banners/games/default/argonauts.png";
import argonautsDesktopBanner from "../images/banners/games/desktop/argonauts.png";
import argonautsLargeBanner from "../images/banners/games/large/argonauts.png";
import argonautsXLargeBanner from "../images/banners/games/xlarge/argonauts.png";
import stampedersDefaultBanner from "../images/banners/games/default/stampeders.png";
import stampedersDesktopBanner from "../images/banners/games/desktop/stampeders.png";
import stampedersLargeBanner from "../images/banners/games/large/stampeders.png";
import stampedersXLargeBanner from "../images/banners/games/xlarge/stampeders.png";
import redblacksDefaultBanner from "../images/banners/games/default/redblacks.png";
import redblacksDesktopBanner from "../images/banners/games/desktop/redblacks.png";
import redblacksLargeBanner from "../images/banners/games/large/redblacks.png";
import redblacksXLargeBanner from "../images/banners/games/xlarge/redblacks.png";
import lionsDefaultBanner from "../images/banners/games/default/lions.png";
import lionsDesktopBanner from "../images/banners/games/desktop/lions.png";
import lionsLargeBanner from "../images/banners/games/large/lions.png";
import lionsXLargeBanner from "../images/banners/games/xlarge/lions.png";
import roughridersDefaultBanner from "../images/banners/games/default/roughriders.png";
import roughridersDesktopBanner from "../images/banners/games/desktop/roughriders.png";
import roughridersLargeBanner from "../images/banners/games/large/roughriders.png";
import roughridersXLargeBanner from "../images/banners/games/xlarge/roughriders.png";
import tigercatsDefaultBanner from "../images/banners/games/default/tigercats.png";
import tigercatsDesktopBanner from "../images/banners/games/desktop/tigercats.png";
import tigercatsLargeBanner from "../images/banners/games/large/tigercats.png";
import tigercatsXLargeBanner from "../images/banners/games/xlarge/tigercats.png";
import greycupDefaultBanner from "../images/banners/games/default/greyCup.png";
import greycupDesktopBanner from "../images/banners/games/desktop/greyCup.png";
import greycupLargeBanner from "../images/banners/games/large/greyCup.png";
import greycupXLargeBanner from "../images/banners/games/xlarge/greyCup.png";
import wranglersDefaultBanner from "../images/banners/games/default/wranglers.png";
import wranglersDesktopBanner from "../images/banners/games/desktop/wranglers.png";
import wranglersLargeBanner from "../images/banners/games/large/wranglers.png";
import wranglersXLargeBanner from "../images/banners/games/xlarge/wranglers.png";
import mooseDefaultBanner from "../images/banners/games/default/moose.png";
import mooseDesktopBanner from "../images/banners/games/desktop/moose.png";
import mooseLargeBanner from "../images/banners/games/large/moose.png";
import mooseXLargeBanner from "../images/banners/games/xlarge/moose.png";
import torontofcDefaultBanner from "../images/banners/games/default/torontofc.png";
import torontofcDesktopBanner from "../images/banners/games/desktop/torontofc.png";
import torontofcLargeBanner from "../images/banners/games/large/torontofc.png";
import torontofcXLargeBanner from "../images/banners/games/xlarge/torontofc.png";
import whitecapsDefaultBanner from "../images/banners/games/default/whitecaps.png";
import whitecapsDesktopBanner from "../images/banners/games/desktop/whitecaps.png";
import whitecapsLargeBanner from "../images/banners/games/large/whitecaps.png";
import whitecapsXLargeBanner from "../images/banners/games/xlarge/whitecaps.png";
import AbbotsfordCanucksDefaultBanner from "../images/banners/games/default/wranglers.png";
import AbbotsfordCanucksDesktopBanner from "../images/banners/games/desktop/wranglers.png";
import AbbotsfordCanucksLargeBanner from "../images/banners/games/large/wranglers.png";
import AbbotsfordCanucksXLargeBanner from "../images/banners/games/xlarge/wranglers.png";

const banners = {
  default: {
    "edmonton-oilers": oilersDefaultBanner,
    "calgary-flames": flamesDefaultBanner,
    "vancouver-canucks": canucksDefaultBanner,
    "winnipeg-jets": jetsDefaultBanner,
    "toronto-maple-leafs": leafsDefaultBanner,
    "ottawa-senators": senatorsDefaultBanner,
    "toronto-raptors": raptorsDefaultBanner,
    "toronto-blue-jays": blueJaysDefaultBanner,
    "edmonton-elks": elksDefaultBanner,
    "winnipeg-blue-bombers": blueBombersDefaultBanner,
    "toronto-argonauts": argonautsDefaultBanner,
    "calgary-stampeders": stampedersDefaultBanner,
    "ottawa-redblacks": redblacksDefaultBanner,
    "bc-lions": lionsDefaultBanner,
    "saskatchewan-roughriders": roughridersDefaultBanner,
    "hamilton-tigercats": tigercatsDefaultBanner,
    "grey-cup": greycupDefaultBanner,
    "calgary-wranglers": wranglersDefaultBanner,
    "manitoba-moose": mooseDefaultBanner,
    "vancouver-whitecaps-fc": whitecapsDefaultBanner,
    "toronto-fc": torontofcDefaultBanner,
    "abbotsford-canucks": AbbotsfordCanucksDefaultBanner
  },
  desktop: {
    "edmonton-oilers": oilersDesktopBanner,
    "calgary-flames": flamesDesktopBanner,
    "vancouver-canucks": canucksDesktopBanner,
    "winnipeg-jets": jetsDesktopBanner,
    "toronto-maple-leafs": leafsDesktopBanner,
    "ottawa-senators": senatorsDesktopBanner,
    "toronto-raptors": raptorsDesktopBanner,
    "toronto-blue-jays": blueJaysDesktopBanner,
    "edmonton-elks": elksDesktopBanner,
    "winnipeg-blue-bombers": blueBombersDesktopBanner,
    "toronto-argonauts": argonautsDesktopBanner,
    "calgary-stampeders": stampedersDesktopBanner,
    "ottawa-redblacks": redblacksDesktopBanner,
    "bc-lions": lionsDesktopBanner,
    "saskatchewan-roughriders": roughridersDesktopBanner,
    "hamilton-tigercats": tigercatsDesktopBanner,
    "grey-cup": greycupDesktopBanner,
    "calgary-wranglers": wranglersDesktopBanner,
    "manitoba-moose": mooseDesktopBanner,
    "vancouver-whitecaps-fc": whitecapsDesktopBanner,
    "toronto-fc": torontofcDesktopBanner,
    "abbotsford-canucks": AbbotsfordCanucksDesktopBanner
  },
  large: {
    "edmonton-oilers": oilersLargeBanner,
    "calgary-flames": flamesLargeBanner,
    "vancouver-canucks": canucksLargeBanner,
    "winnipeg-jets": jetsLargeBanner,
    "toronto-maple-leafs": leafsLargeBanner,
    "ottawa-senators": senatorsLargeBanner,
    "toronto-raptors": raptorsLargeBanner,
    "toronto-blue-jays": blueJaysLargeBanner,
    "edmonton-elks": elksLargeBanner,
    "winnipeg-blue-bombers": blueBombersLargeBanner,
    "toronto-argonauts": argonautsLargeBanner,
    "calgary-stampeders": stampedersLargeBanner,
    "ottawa-redblacks": redblacksLargeBanner,
    "bc-lions": lionsLargeBanner,
    "saskatchewan-roughriders": roughridersLargeBanner,
    "hamilton-tigercats": tigercatsLargeBanner,
    "grey-cup": greycupLargeBanner,
    "calgary-wranglers": wranglersLargeBanner,
    "manitoba-moose": mooseLargeBanner,
    "vancouver-whitecaps-fc": whitecapsLargeBanner,
    "toronto-fc": torontofcLargeBanner,
    "abbotsford-canucks": AbbotsfordCanucksLargeBanner
  },
  xlarge: {
    "edmonton-oilers": oilersXLargeBanner,
    "calgary-flames": flamesXLargeBanner,
    "vancouver-canucks": canucksXLargeBanner,
    "winnipeg-jets": jetsXLargeBanner,
    "toronto-maple-leafs": leafsXLargeBanner,
    "ottawa-senators": senatorsXLargeBanner,
    "toronto-raptors": raptorsXLargeBanner,
    "toronto-blue-jays": blueJaysXLargeBanner,
    "edmonton-elks": elksXLargeBanner,
    "winnipeg-blue-bombers": blueBombersXLargeBanner,
    "toronto-argonauts": argonautsXLargeBanner,
    "calgary-stampeders": stampedersXLargeBanner,
    "ottawa-redblacks": redblacksXLargeBanner,
    "bc-lions": lionsXLargeBanner,
    "saskatchewan-roughriders": roughridersXLargeBanner,
    "hamilton-tigercats": tigercatsXLargeBanner,
    "grey-cup": greycupXLargeBanner,
    "calgary-wranglers": wranglersXLargeBanner,
    "manitoba-moose": mooseXLargeBanner,
    "vancouver-whitecaps-fc": whitecapsXLargeBanner,
    "toronto-fc": torontofcXLargeBanner,
    "abbotsford-canucks": AbbotsfordCanucksXLargeBanner
  },
};

export default banners;