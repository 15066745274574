import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import validator from "validator";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import { ReCaptcha } from "react-recaptcha-google";
import { API } from "aws-amplify";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// import config from '../config';

const styles = {
  userDetails: {
    marginTop: "1.5rem",
    paddingBottom: "2rem",
    marginBottom: "2rem",
    borderBottom: "1px solid #f1f1f1",
  },
  form: {
    marginTop: "1.5rem",
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    width: "100%",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  cardFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginTop: ".5rem",
    marginBottom: ".25rem",
  },
  cardField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    width: "100%",
    marginTop: ".5rem",
    marginBottom: ".25rem",
  },
  progress: {
    margin: "0 1rem",
  },
  formHelperText: {
    marginTop: 4,
    marginBottom: 8,
    fontSize: ".75rem",
    color: "#e03a3e",
  },
  couponContainer: {
    borderRadius: "4px",
    border: "1px solid #e5e5ea",
    marginTop: "1rem",
    padding: "0.75rem",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    fontSize: 16,
  },
};

function ExpiryDateCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="##/##"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CVVCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CardCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="#### #### #### ####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function MobileNoCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            id: "mobileNo",
          },
        });
      }}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CustomZipCodeInput(props) {
  const { inputRef, value, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
      pipe={(conformedValue) => conformedValue.toUpperCase()}
      placeholderChar={"\u2000"}
    />
  );
}

const preventCopyPaste = (event) => {
  event.preventDefault();
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      confirmEmail: "",
      firstName: "",
      lastName: "",
      mobileNo: "",
      card: "",
      expiryDate: "",
      cvv: "",
      zipCode: "",
      address: "",
      isProcessing: false,
      isCardComplete: false,
      isValidMobileNo: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidEmail: true,
      isValidConfirmEmail: true,
      isValidCard: true,
      isLoading: false,
      isValidExpiryDate: true,
      isValidCVV: true,
      isValidZipCode: true,
      isValidAddress: true,
      isCaptchaPassed: false,
      couponId: "",
      coupon: {},
      error: false,
    };

    this.formRef = React.createRef();
    this.handleCustomTextFieldChange =
      this.handleCustomTextFieldChange.bind(this);

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  handleEmailChange = (event) => {
    const { confirmEmail } = this.state;
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.validateEmail(event.target.value);
    this.validateEmails(event.target.value, confirmEmail);
  };

  handleConfirmEmailChange = (event) => {
    const { email } = this.state;
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.validateEmail(event.target.value, 'confirm');
    this.validateEmails(email, event.target.value);
  };

  validateEmail = (email, field = 'email') => {
    if (!validator.isEmail(email)) {
      if (field === 'email') {
        this.setState({
          isValidEmail: validator.isEmail(email.trim())
        });
      } else {
        this.setState({
          isValidConfirmEmail: validator.isEmail(email.trim())
        });
      }
    }else{
      this.setState({
        isValidEmail: true,
        isValidConfirmEmail: true,
      });
    }
  };

  validateEmails = (email, confirmEmail) => {
    if (email && confirmEmail) {
      this.setState({
        error: email.trim() !== confirmEmail.trim(),
      });
    } else {
      this.setState({
        error: false,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: nextProps.isLoading,
        isProcessing: nextProps.isLoading,
      });
    }
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  verifyCallback(recaptchaToken) {
    this.setState({
      isCaptchaPassed: true,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCardFieldChange = (event) => {
    this.setState({
      isCardComplete: event.complete,
      isValidCard: event.complete,
    });
  };

  handleSubmitClick = async (event) => {
    event.preventDefault();

    this.setState({
      isProcessing: true,
    });

    const {
      firstName,
      lastName,
      email,
      mobileNo,
      card,
      cvv,
      expiryDate,
      zipCode,
    } = this.state;

    if (!this.isValid()) {
      this.setState({
        isProcessing: false,
      });
      return false;
    }

    this.props.onSubmit({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo.trim(),
      card,
      cvv,
      expiryDate,
      zipCode: zipCode.trim(),
    });
  };

  isValidName = (name) => {
    return name.trim() !== "";
  };

  isValidMobileNo = (mobileNo) => {
    const re =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return re.test(mobileNo);
  };

  isValidZipCode = (zipCode) => {
    const re = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/gi;
    return re.test(zipCode);
  };

  isValidAddress = (address) => {
    return address !== "";
  };
  calculateTotal(total, discount) {
    if (!discount) {
      return total;
    } else {
      return total - discount < 0 ? 0 : total - discount;
    }
  }
  isValid() {
    const {
      email,
      confirmEmail,
      firstName,
      lastName,
      mobileNo,
      card,
      expiryDate,
      cvv,
      zipCode,
    } = this.state;
    const isValidFirstName = this.isValidName(firstName);
    const isValidLastName = this.isValidName(lastName);
    const isValidMobileNo = this.isValidMobileNo(mobileNo);
    const isValidEmail = validator.isEmail(email.trim());
    const isValidConfirmEmail = validator.isEmail(confirmEmail.trim());
    const isValidCard = card ? validator.isCreditCard(card) : false;
    const isValidExpiryDate = expiryDate
      ? RegExp(/(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])/).test(expiryDate)
      : false;
    const isValidCVV = cvv ? RegExp(/^[0-9]{3,4}$/).test(cvv) : false;
    const isValidZipCode = this.isValidZipCode(zipCode);
    // const isValidAddress = this.isValidAddress(address);
    
    this.setState({
      isValidEmail,
      isValidConfirmEmail,
      isValidFirstName,
      isValidLastName,
      isValidMobileNo,
      isValidCard,
      isValidExpiryDate,
      isValidCVV,
      isValidZipCode,
      // isValidAddress,
    });

    this.scrollToDomRef();

    return (
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isValidConfirmEmail &&
      isValidMobileNo &&
      ((isValidCard && isValidExpiryDate && isValidCVV) ||
        this.calculateTotal(
          parseFloat(this.props.amount),
          parseFloat(this.state.coupon.value)
        ) === 0) &&
      isValidZipCode
      // isValidAddress
    );
  }

  scrollToDomRef = () => {
    const domNode = ReactDOM.findDOMNode(this.formRef.current);

    window.scrollTo(0, domNode.offsetTop - 32);
  };
  async applyCoupon(couponId) {
    couponId = couponId.trim();
    try {
      const coupon = await API.get("v2", `marketplace/coupons/${couponId}`);
      if (coupon.isActive) {
        this.props.onCouponApplied(coupon);
        this.setState({ coupon });
      } else {
        alert("Sorry, the coupon you entered is no longer valid.");
      }
    } catch (error) {
      console.log("[ERROR]: ", error);
      this.setState({ coupon: {} });
      alert("Sorry, we couldn't find the coupon code you entered.");
    }
  }

  async removeCoupon() {
    this.setState({
      couponId: "",
      coupon: {},
    });
  }

  handleCustomTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderCouponInput() {
    const { classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={9}>
          <FormControl error={false} fullWidth={true}>
            <TextField
              id="couponId"
              placeholder="Coupon Code"
              className={classes.textField}
              value={this.state.couponId}
              onChange={this.handleChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            error={false}
            fullWidth={true}
            style={{ verticalAlign: "bottom" }}
          >
            <Button
              onClick={() => this.applyCoupon(this.state.couponId)}
              style={{
                padding: ".75rem",
                marginTop: ".75rem",
                marginBottom: 0,
                borderRadius: "4px",
                backgroundColor: "#00de94",
                border: "solid 1px #e5e5ea",
                height: "1rem",
              }}
            >
              <Typography style={{ color: "white" }}>Apply</Typography>
            </Button>{" "}
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderCouponCard() {
    const { classes } = this.props;
    return (
      <div className={classes.couponContainer}>
        {/* <Typography
          variant="caption"
          style={{ marginBottom: ".5rem" }}
          color="primary"
        >
          {this.state.coupon.value &&
          this.state.coupon.value - this.state.coupon.valueRedeemed >
            this.props.amount
            ? `$${this.props.amount.toFixed(
                2
              )}/$${this.state.coupon.value.toFixed(
                2
              )} coupon applied! - $${(
                this.state.coupon.value -
                this.state.coupon.valueRedeemed -
                this.props.amount
              ).toFixed(2)} remaining`
            : `$${this.state.coupon.value.toFixed(2)} coupon applied!`}
        </Typography> */}
        <div className={classes.couponTextContainer}>
          <Typography variant="body1" style={{ marginBottom: "0.5rem" }}>
            Coupon Applied!{" "}
          </Typography>
          <Typography variant="caption">
            {this.state.coupon.value &&
            this.state.coupon.value - this.state.coupon.valueRedeemed >
              this.props.amount
              ? `$${this.props.amount.toFixed(2)} redeemed - $${(
                  this.state.coupon.value -
                  this.state.coupon.valueRedeemed -
                  this.props.amount
                ).toFixed(2)} remaining on coupon`
              : `$${
                  this.state.coupon.value.toFixed(2) -
                  this.state.coupon.valueRedeemed.toFixed(2)
                } coupon applied!`}
          </Typography>
          <Typography
            variant="caption"
            style={{ color: "rgba(0, 0, 0, 0.54)", display: "inline" }}
          >
            New Total:{" "}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: "#f44336",
              textDecoration: "line-through",
              display: "inline",
            }}
          >
            ${this.props.amount.toFixed(2)}
          </Typography>{" "}
          <Typography
            variant="caption"
            style={{ color: "green", display: "inline" }}
          >
            $
            {this.calculateTotal(
              this.props.amount,
              parseFloat(this.state.coupon.value)
            ).toFixed(2)}
          </Typography>
          <Typography
            variant="caption"
            color="primary"
            style={{ marginTop: "0.5rem" }}
          >
            {this.state.coupon.id}
          </Typography>
        </div>
        <IconButton
          onClick={() => this.removeCoupon()}
          variant="text"
          color="default"
          size="small"
          className={classes.deleteButton}
        >
          <DeleteIcon color="error"></DeleteIcon>
        </IconButton>
      </div>
    );
  }

  render() {
    // const loading = this.state.isProcessing || this.props.loading;
    const { classes } = this.props;
    return (
      <div className={classes.userDetails}>
        <Typography
          variant="caption"
          color="primary"
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Enter Your Details
        </Typography>

        <form
          ref={this.formRef}
          className={classes.form}
          autoComplete="off"
          onSubmit={this.handleSubmitClick}
        >
          <FormControl error={!this.state.isValidFirstName} fullWidth={true}>
            <TextField
              id="firstName"
              placeholder="First Name"
              fullWidth={true}
              className={
                this.state.isValidFirstName
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.firstName}
              onChange={this.handleChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />

            {!this.state.isValidFirstName ? (
              <FormHelperText className={classes.formHelperText}>Enter your first name</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={!this.state.isValidLastName} fullWidth={true}>
            <TextField
              id="lastName"
              placeholder="Last Name"
              fullWidth={true}
              className={
                this.state.isValidLastName
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.lastName}
              onChange={this.handleChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />

            {!this.state.isValidLastName ? (
              <FormHelperText className={classes.formHelperText}>Enter your last name</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={!this.state.isValidEmail} fullWidth={true} onCopy={preventCopyPaste} onPaste={preventCopyPaste}>
            <TextField
              id="email"
              placeholder="Email Address"
              fullWidth={true}
              className={
                this.state.isValidEmail
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.email}
              onChange={this.handleEmailChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                endAdornment: this.state.email && (
                  <InputAdornment position="end">
                    {!this.state.isValidEmail ? (
                      <CloseIcon color="error" className={classes.icon} />
                    ):(
                      <CheckIcon color="success" className={classes.icon} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {!this.state.isValidEmail ? (
              <FormHelperText className={classes.formHelperText}>Enter valid email</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={!this.state.isValidConfirmEmail || this.state.error} fullWidth={true} onCopy={preventCopyPaste} onPaste={preventCopyPaste}>
            <TextField
              id="confirmEmail"
              placeholder="Retype Email Address"
              fullWidth={true}
              className={
                this.state.isValidConfirmEmail
                ? this.state.error 
                ? classes.textFieldError : classes.textField 
                : classes.textFieldError
              }
              value={this.state.confirmEmail}
              onChange={this.handleConfirmEmailChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                endAdornment: this.state.confirmEmail && (
                  <InputAdornment position="end">
                    {!this.state.isValidConfirmEmail ? (
                      <CloseIcon color="error" className={classes.icon} />
                    ) : this.state.error ? (
                      <CloseIcon color="error" className={classes.icon} />
                    ):(
                      <CheckIcon color="success" className={classes.icon} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {!this.state.isValidConfirmEmail ? (
              <FormHelperText className={classes.formHelperText}>Enter valid email</FormHelperText>
            ) : this.state.error ? (
              <FormHelperText className={classes.formHelperText}>Email addresses do not match</FormHelperText>
            ): null}
          </FormControl>
          <FormControl error={!this.state.isValidMobileNo} fullWidth={true}>
            <TextField
              id="mobileNo"
              placeholder="Mobile Number"
              fullWidth={true}
              className={
                this.state.isValidMobileNo
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.mobileNo}
              onChange={this.handleChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                startAdornment: (
                  <span style={{ fontSize: ".875rem", marginRight: ".5rem" }}>
                    +1
                  </span>
                ),
                inputComponent: MobileNoCustomInput,
              }}
            />

            {!this.state.isValidMobileNo ? (
              <FormHelperText className={classes.formHelperText}>Enter your mobile number</FormHelperText>
            ) : null}
          </FormControl>
          <Typography
            variant="subheading"
            style={{ margin: "1.5rem 0 .25rem" }}
          >
            Card Information
          </Typography>
          <Typography
            variant="caption"
            style={{ marginBottom: ".5rem" }}
            color="primary"
          >
            All payments are secured and encrypted via SSL / TLS technology
          </Typography>
          <FormControl error={!this.state.isValidMobileNo} fullWidth={true}>
            <TextField
              id="card"
              placeholder="Card Number"
              fullWidth={true}
              className={
                this.state.isValidCard ||
                this.calculateTotal(
                  parseFloat(this.props.amount),
                  parseFloat(this.state.coupon.value)
                ) == 0
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.card}
              onChange={this.handleCustomTextFieldChange("card")}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                inputComponent: CardCustomInput,
              }}
            />
            {!this.state.isValidCard &&
            this.calculateTotal(
              parseFloat(this.props.amount),
              parseFloat(this.state.coupon.value)
            ) > 0 ? (
              <FormHelperText className={classes.formHelperText}>Enter valid card number</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl
            error={
              !this.state.isValidExpiryDate &&
              this.calculateTotal(
                parseFloat(this.props.amount),
                parseFloat(this.state.coupon.value)
              ) > 0
            }
            fullWidth={true}
          >
            <TextField
              id="expiryDate"
              placeholder="Expiry Date (MM/YY)"
              className={
                this.state.isValidExpiryDate ||
                this.calculateTotal(
                  parseFloat(this.props.amount),
                  parseFloat(this.state.coupon.value)
                ) === 0
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.expiryDate}
              onChange={this.handleCustomTextFieldChange("expiryDate")}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                inputComponent: ExpiryDateCustomInput,
              }}
            />
            {!this.state.isValidExpiryDate &&
            this.calculateTotal(
              parseFloat(this.props.amount),
              parseFloat(this.state.coupon.value)
            ) > 0 ? (
              <FormHelperText className={classes.formHelperText}>Enter your card's expiry date (i.e. 01/12)</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl
            error={
              !this.state.isValidCVV &&
              this.calculateTotal(
                parseFloat(this.props.amount),
                parseFloat(this.state.coupon.value)
              ) > 0
            }
            fullWidth={true}
          >
            <TextField
              id="cvv"
              placeholder="Card Security No."
              className={
                this.state.isValidCVV ||
                this.calculateTotal(
                  parseFloat(this.props.amount),
                  parseFloat(this.state.coupon.value)
                ) === 0
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.cvv}
              onChange={this.handleCustomTextFieldChange("cvv")}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                inputComponent: CVVCustomInput,
              }}
            />
            {!this.state.isValidCVV &&
            this.calculateTotal(
              parseFloat(this.props.amount),
              parseFloat(this.state.coupon.value)
            ) > 0 ? (
              <FormHelperText className={classes.formHelperText}>Enter your 3 or 4 digit CVV</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={!this.state.isValidZipCode} fullWidth={true}>
            <TextField
              id="zipCode"
              placeholder="Postal Code"
              fullWidth={true}
              className={
                this.state.isValidZipCode
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.zipCode}
              disabled={this.state.isProcessing || this.props.isLoading}
              disableUnderline
              onChange={(e) => {
                this.setState({
                  zipCode: e.target.value.toUpperCase(),
                });
              }}
              margin="dense"
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                inputComponent: CustomZipCodeInput,
              }}
            />

            {!this.state.isValidZipCode ? (
              <FormHelperText className={classes.formHelperText}>Enter your postal code</FormHelperText>
            ) : null}
          </FormControl>{" "}
          <Typography
            variant="subheading"
            style={{ margin: "1.5rem 0 .25rem" }}
          >
            Coupon Information
          </Typography>
          {this.state.coupon.value
            ? this.renderCouponCard()
            : this.renderCouponInput()}
          <div style={{ marginTop: "1rem" }}>
            <ReCaptcha
              ref={(el) => {
                this.captchaDemo = el;
              }}
              size="normal"
              render="explicit"
              sitekey="6LfzJ94UAAAAAFjlPyI3OhRU7YQehfJpP0nOk2VW"
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
              data-badge="bottomleft"
            />
          </div>
          <Button
            disableRipple={true}
            fullWidth
            variant="contained"
            onClick={this.handleSubmitClick}
            disabled={
              this.state.isProcessing ||
              this.state.isLoading ||
              !this.state.isCaptchaPassed
            }
            style={{
              marginTop: ".5rem",
              padding: "1rem",
            }}
            color="primary"
          >
            {this.state.isProcessing || this.state.isLoading ? (
              <React.Fragment>
                <CircularProgress className={classes.progress} size={14} />
                Submitting Payment...
              </React.Fragment>
            ) : (
              `Pay Now ${this.calculateTotal(
                parseFloat(this.props.amount),
                parseFloat(this.state.coupon.value)
              ).toFixed(2)} CAD`
            )}
          </Button>
        </form>
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CheckoutForm);
