import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Nav from '../components/OldNav';
import Footer from '../components/OldFooter';

const PlainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Nav />
      </Hidden>
      {children}
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
};

export default PlainLayout;
