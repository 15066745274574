import React from 'react';
import PropTypes from 'prop-types';
import ReginaSeatMap from './ReginaSeatMap';
import NhlHeritageSeatMap from './NhlHeritageSeatMap';
import CanucksBasketballSeatMap from './seatmaps/CanucksBasketballSeatMap';
import WhitecapsSpecialSeatMap from './WhitecapsSpecialSeatMap';
import LionsSpecialSeatMap from './LionsSpecialSeatMap';

const SpecialSeatMapSelector = ({ homeTeamSlug, filteredSeats, onSeatmapItemClick, zonesList }) => {
  if (homeTeamSlug === "edmonton-oilers") {
    return (
      <NhlHeritageSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    )
  } else if (homeTeamSlug === "toronto-raptors") {
    return (
      <CanucksBasketballSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    )
  } else if (homeTeamSlug === "vancouver-whitecaps-fc") {
    return (
      <WhitecapsSpecialSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    )
  } else if (homeTeamSlug === "bc-lions") {
    return (
      <LionsSpecialSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    )
  } else {
    return (
      <ReginaSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    )
  }
}

SpecialSeatMapSelector.propTypes = {
  homeTeamSlug: PropTypes.string.isRequired,
  filteredSeats: PropTypes.array,
  onSeatmapItemClick: PropTypes.func.isRequired,
  zonesList: PropTypes.array.isRequired
};

SpecialSeatMapSelector.defaultProps = {
  filteredSeats: [],
};

export default SpecialSeatMapSelector;
