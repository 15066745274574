import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import moment from "moment";
import MediaQuery from "react-responsive";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import PendingSeatsTable from "../components/PendingSeatsTable";
import ActiveSeatsTable from "../components/ActiveSeatsTable";
import SellHistory from "../components/SellHistory";
import SellerSoldOutSeatsTable from "../components/SellerSoldOutSeatsTable";

import getTimezone from "../libs/get-timezone";

const styles = (theme) => ({
  alert: {
    padding: "1rem",
    backgroundColor: "#ffffc7",
  },
  stickyTabs: {
    position: "-webkit-sticky",
    position: "sticky",
    backgroundColor: "#fafafa",
    zIndex: "1",
    top: "188px",
    "@media (max-width: 959px)": {
      top: "216px",
    },
  },
  header: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "0px",
    backgroundColor: "#fafafa",
    zIndex: "1",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    //fontSize: "14px"
  },
  indicator: {
    backgroundColor: "#00a0ff",
    height: "5px",
    borderRadius: "25px 25px 0px 0px",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      padding: "1rem",
      paddingBottom: "2rem",
      position: "-webkit-sticky",
      position: "sticky",
      top: "0px",
      backgroundColor: "#fafafa",
    },
    btnsContainer: {
      marginTop: "2rem",
      borderRadius: "99px",
      display: "flex",
    },
    listingsText: {
      display: "block",
      paddingTop: "65px",
      fontSize: "32px",
      marginTop: "-40px",
      fontWeight: "bold",
      fontFamily: "Nunito Sans",
    },
    transferButton: {
      marginRight: "1rem",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
      fontFamily: "Nunito Sans",
      "& button": {
        /* FOR MODIFYING WIDHT OF EACH TAB */
        minWidth: 150,
        fontFamily: "Nunito Sans",
        fontWeight: "600",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      paddingTop: "6rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
    },
    btnsContainer: {
      marginTop: "1rem",
      borderRadius: "99px",
      display: "flex",
      justifyContent: "space-between",
    },
    transferButton: {
      marginRight: "1rem",
      marginTop: "1rem",
    },
    flexContainer: {
      justifyContent: "space-around",
    },
    listingsText: {
      display: "block",
      paddingTop: "65px",
      fontSize: "32px",
      marginTop: "-60px",
      fontWeight: "bold",
      fontFamily: "Nunito Sans",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
      "& button": {
        /* FOR MODIFYING WIDHT OF EACH TAB */
        minWidth: 100,
      },
    },
  },

  progress: {
    height: 24,
    width: 24,
    marginLeft: "1rem",
  },
});

class Listings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      pendingGames: [],
      history: [],
      tab: "active",
      isLoading: true,
      seats: null,
      activeSeats: null,
      openNewListingsPopup: false,
      isEditing: false,
      submitting: false,
      discarding: false,
      lastEvaluatedKey: null,
      lastEvaluatedHistoryKey: null,
      loadMoreLoading: false,
      lastEvaluatedSellRequestsKey: null
    };

    this.setSeats = this.setSeats.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.updateSeats = this.updateSeats.bind(this);
    this.makeMoreChanges = this.makeMoreChanges.bind(this);
    this.setPendingRequest = this.setPendingRequest.bind(this);
  }

  intervalID = 0;

  async setPendingRequest() {
    const requests = await this.requests();
    if (!requests) {
      return;
    }
    const arrangedRequests = _.chain(requests.sell_requests)
      .orderBy("createdAt", "desc")
      .value();

    const pendingGames = _.chain(arrangedRequests)
      .flatMap((request) => {
        return request.games.map((game) => {
          return {
            ...game,
            ...{
              section: request.section,
              sectionNo: request.sectionNo,
              row: request.row,
              noOfSeats: request.noOfSeats,
            },
          };
        });
      })
      .orderBy(["date", "orderNo"], "asc")
      .value();
    this.setState({
      ...this.state,
      requests: arrangedRequests,
      pendingGames,
      isLoading: false,
      lastEvaluatedSellRequestsKey: requests.lastEvaluatedSellRequestsKey,
    });
  };

  async setSeats(includeInactive = false, tab = "active") {
    const seats = await this.seats(includeInactive, tab);
    if (!seats) {
      return;
    }
    const seatLists = seats.listings;
    const {
      activeSeats: activeSeatsState,
      history: historyState,
      lastEvaluatedKey,
      lastEvaluatedHistoryKey,
    } = this.state;
    /* const orders = await this.orders(); */

    const arrangedSeats = _.orderBy(
      seatLists,
      ["gameDate", "zoneNo", "zone", "row", "price"],
      "asc"
    );
    const activeSeats = _.chain(arrangedSeats)
      .filter(
        (seat) =>
          !seat.isSold &&
          !seat.isArchived &&
          (!seat.gameDate ||
            seat.gameDate >=
              moment.tz(getTimezone(seat.game)).subtract(1, "hour").valueOf())
      )
      .orderBy(["gameDate", "gameName", "zoneNo", "zone", "row", "price"], "asc")
      .value();

    const soldOutSeats = _.filter(
      arrangedSeats,
      (seat) => (seat.isSold || seat.seatsSold > 0) && !seat.isArchived
    );

    const history = _.chain(arrangedSeats)
      .filter((seat) => {
        return (
          (seat.gameDate &&
            seat.gameDate <=
              moment
                .tz(getTimezone(seat.game))
                .subtract(1, "hour")
                .valueOf()) ||
          seat.isArchived ||
          seat.isSold
        );
      })
      .orderBy(["gameDate", "orderNo"], "desc")
      .value();

    /* let sortedOrders = _.orderBy(orders, (order) => order.createdAt, "desc"); */
    const isHistory = tab === "history";
    const preActiveState = activeSeatsState ? activeSeatsState : [];
    const preHistory = historyState ? historyState : [];
    this.setState({
      ...this.state,
      seats: arrangedSeats,
      activeSeats: isHistory
        ? activeSeatsState
        : [...preActiveState, ...activeSeats],
      soldOutSeats,
      history: isHistory ? [...preHistory, ...history] : historyState,
      isLoading: false,
      loadMoreLoading: false,
      lastEvaluatedKey: isHistory ? lastEvaluatedKey : seats.lastEvaluatedKey,
      lastEvaluatedHistoryKey: isHistory
        ? seats.lastEvaluatedKey
        : lastEvaluatedHistoryKey,
    });
  }

  componentWillMount() {
    if (!this.props.isAuthenticated || !this.props.currUser) {
      this.props.history.push("/login");
    }
    if (this.props.currUser && !this.props.currUser.email_verified) {
      this.props.history.push(`/email-verification`);
    }

    this.setSeats()
      .then(() => {
        this.setPendingRequest()
        .then(() => {
          if (this.state.activeSeats && this.state.pendingGames) {
            if (this.state.activeSeats.length === 0 && this.state.pendingGames.length === 0) {
              this.setState({ isLoading: false, openNewListingsPopup: true });
            }
          }
        });
      });
  }

  componentDidMount() {
    //this.intervalID = setInterval(this.setSeats, 60,000);  // Leave uncommented
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  async sendVerificationCode(e) {
    e.preventDefault();

    try {
      await Auth.verifyCurrentUserAttribute("phone_number");
      this.props.history.push("/account-verification");
    } catch (e) {
      console.error(e);
    }
  }

  renderAccountVerification() {
    const { classes } = this.props;
    // const { email_verified, mobile_number_verified } = this.props.currUser;

    return (
      <div className={classes.alert}>
        <Typography
          align="center"
          variant="body2"
          style={{ fontSize: ".875rem" }}
        >
          Your mobile number is not yet verified.{" "}
          <button
            style={{
              color: "#2196f3",
              textDecoration: "underline",
              background: "transparent",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: "0.875rem",
              border: "none",
              padding: 0,
              margin: 0,
              cursor: "pointer",
            }}
            onClick={(e) => this.sendVerificationCode(e)}
            className={classes.link}
          >
            Click here
          </button>{" "}
          to verify.
        </Typography>
      </div>
    );
  }

  async requests() {
    const { currUser } = this.props;
    const { lastEvaluatedSellRequestsKey } = this.state;
    if(currUser){
      return API.get(
        "v2",
        `marketplace/sellRequests/paginated/by/sellerId/${currUser.sub}${
          lastEvaluatedSellRequestsKey ? `?lastEvaluatedSellRequestsKey=${JSON.stringify(lastEvaluatedSellRequestsKey)}` : ""
        }`
      );
    }
  }

  async seats(includeInactive = false, tag = "active") {
    const { currUser } = this.props;
    const { lastEvaluatedKey, lastEvaluatedHistoryKey } = this.state;
    const lastKey =
      tag === "history" ? lastEvaluatedHistoryKey : lastEvaluatedKey;
    if(currUser){
      const response = await API.get(
        "v2",
        `marketplace/listings/by/paginated/sellerId/${
          currUser.sub
        }?includeInactive=${includeInactive}${
          lastKey ? `&lastEvaluatedKey=${JSON.stringify(lastKey)}` : ""
        }`
      );
      return response;
    }
  }

  renderMain() {
    const { tab, history } = this.state;
    const { classes } = this.props;
    return (
      <main>
        <Grid container>
          <Grid item xs={12} className={classes.stickyTabs}>
            <Tabs
              value={tab}
              variant="fullWidth"
              onChange={(event, newValue) => {
                this.setState({ tab: newValue, isEditing: false });
                if (newValue === "active") {
                  this.setState({
                    ...this.state,
                    activeSeats: [],
                    isLoading: true,
                    tab: newValue,
                  });
                  this.setSeats();
                } else if (newValue === "history" && history.length === 0) {
                  this.setState({
                    ...this.state,
                    isLoading: true,
                    tab: newValue,
                  });
                  this.setSeats(true, newValue);
                } else if (newValue === "pending") {
                  this.setState({
                    ...this.state,
                    isLoading: true,
                    tab: newValue,
                  });
                  this.setPendingRequest();
                }
              }}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
                flexContainer: classes.flexContainer,
              }}
            >
              <Tab
                value="active"
                label={
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: tab === "active" ? "#00A0FF" : "#5F666F",
                      fontSize: "18px",
                    }}
                  >
                    Active
                  </Typography>
                }
                style={{
                  textTransform: "none",
                  color: tab === "active" ? "#00A0FF" : "#5F666F",
                  fontSize: "1rem",
                }}
              />
              <Tab
                value="pending"
                label={
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: tab === "pending" ? "#00A0FF" : "#5F666F",
                      fontSize: "18px",
                    }}
                  >
                    Pending
                  </Typography>
                }
                style={{
                  textTransform: "none",
                  color: tab === "pending" ? "#00A0FF" : "#5F666F",
                }}
              />
              <Tab
                value="sold"
                label={
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: tab === "sold" ? "#00A0FF" : "#5F666F",
                      fontSize: "18px",
                    }}
                  >
                    Sold
                  </Typography>
                }
                style={{
                  textTransform: "none",
                  color: tab === "sold" ? "#00A0FF" : "#5F666F",
                }}
              />
              <Tab
                value="history"
                label={
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: tab === "history" ? "#00A0FF" : "#5F666F",
                      fontSize: "18px",
                    }}
                  >
                    History
                  </Typography>
                }
                style={{
                  textTransform: "none",
                  color: tab === "history" ? "#00A0FF" : "#5F666F",
                }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderListings()}
          </Grid>
        </Grid>
      </main>
    );
  }

  refreshListings = () => {
    this.seats()
     .then(() => {
        this.setPendingRequest()
         .then(() => {
            if (this.state.activeSeats.length === 0 && this.state.pendingGames.length === 0) {
              this.setState({ isLoading: false, openNewListingsPopup: true });
            }
            this.render();
          });
      });
  };

  updateActiveSeats = (newActiveSeats) => {
    this.setState({ activeSeats: newActiveSeats });
  }

  renderListings() {
    const {
      requests,
      isLoading,
      pendingGames,
      tab,
      activeSeats,
      isEditing,
      lastEvaluatedKey,
      lastEvaluatedHistoryKey,
      history,
      loadMoreLoading,
      lastEvaluatedSellRequestsKey
    } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          >
            <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading your listings...
          </Typography>
        </React.Fragment>
      );
    }
    if (requests) {
      return (
        <React.Fragment>
          {tab === "active" && (
            <ActiveSeatsTable
              seats={activeSeats ? activeSeats : null}
              updateActiveSeats={this.updateActiveSeats}
              editing={isEditing}
              setEditing={this.handleEditClose}
              updateSeats={this.updateSeats}
              submitting={this.state.submitting}
              discarding={this.state.discarding}
              makeMoreChanges={this.makeMoreChanges}
              onSeatArchived={this.refreshListings}
              currUser={this.props.currUser}
            />
          )}
          {tab === "pending" && (
            <PendingSeatsTable games={pendingGames ? pendingGames : null} />
          )}
          {tab === "sold" && (
            <SellerSoldOutSeatsTable currUser={this.props.currUser} />
          )}
          {tab === "history" && (
            <SellHistory seats={history ? history : null} />
          )}
          {tab === "history" && lastEvaluatedHistoryKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setSeats(true, "history");
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              color="secondary"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
          {tab === "active" && lastEvaluatedKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setSeats(false, "active");
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
              color="secondary"
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
          {tab === "pending" && lastEvaluatedSellRequestsKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setPendingRequest();
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              color="secondary"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
        </React.Fragment>
      );
    }
  }

  renderNewListingsPopup() {
    const { openNewListingsPopup, isLoading } =
      this.state;

    if (isLoading) {
      return;
    }
    return (
      <Dialog
        open={openNewListingsPopup}
        onClose={() =>
          this.setState({ openNewListingsPopup: !openNewListingsPopup })
        }
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">
          Ready to list your tickets?
          <IconButton
            disableRipple={true}
            disableTouchRipple={true}
            onClick={() =>
              this.setState({
                openNewListingsPopup: !openNewListingsPopup,
              })
            }
            style={{
              padding: "0.5rem",
              position: "absolute",
              top: "12px",
              right: "8px",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            You don’t have any active or pending listings right now. Click
            below to get started
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button href="/listings/new" variant="contained" color="secondary">
            Add New Listing
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  handleEditClose() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  updateSeats(editedSeats) {
    this.setState({ activeSeats: editedSeats, isEditing: false });
  }
  makeMoreChanges() {
    this.setState({ submitting: false }, () => {
      this.render();
    });
  }
  applyChanges() {
    // make api call
    this.setState({ submitting: true });
  }
  discardChanges() {
    this.handleEditClose();
    this.setState({ discarding: true, submitting: false });
  }
  render() {
    const { classes } = this.props;
    const { isEditing, tab } = this.state;
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        {/* {isAuthenticated &&
          !currUser.phone_number_verified &&
          this.renderAccountVerification()} */}
        <Helmet>
          <title>Listings</title>
          <meta name="description" content="Ticket listings by sellers" />
          <meta name="keywords" content={`listing, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Listings" />
          <meta property="og:description" content="Ticket listings by sellers" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {/* <MobileViewWarning></MobileViewWarning> */}
        <Grid container justify="center">
          <Grid item xs={12} md={10} className={classes.header}>
            <Typography
              variant="headline"
              color="primary"
              className={classes.listingsText}
            >
              Listings
            </Typography>
            <div className={classes.btnsContainer}>
              <MediaQuery maxWidth={614}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  href="/listings/new"
                  style={{
                    marginRight: "1rem",
                    borderRadius: "99px",
                    textTransform: "none",
                    fontFamily: "Nunito Sans",
                    fontWeight: "600",
                  }}
                >
                  + Add
                </Button>
                <div className={classes.editingBtnsContainer}>
                  {tab === "active" && !isEditing && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      style={{
                        marginRight: "1rem",
                        borderRadius: "99px",
                        textTransform: "none",
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        this.setState({
                          isEditing: !isEditing,
                          submitting: false,
                          discarding: false,
                        })
                      }
                    >
                      <EditIcon></EditIcon>
                    </Button>
                  )}
                  {tab === "active" && isEditing && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          marginRight: "1rem",
                          borderRadius: "99px",
                          textTransform: "none",
                          fontFamily: "Nunito Sans",
                          fontWeight: "600",
                          color: "white",
                          backgroundColor: "#00DE94",
                        }}
                        onClick={() => this.applyChanges()}
                      >
                        <CheckIcon></CheckIcon>
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        style={{
                          marginRight: "1rem",
                          borderRadius: "99px",
                          textTransform: "none",
                          fontFamily: "Nunito Sans",
                          fontWeight: "600",
                        }}
                        onClick={() => this.discardChanges()}
                      >
                        <ClearIcon></ClearIcon>
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </MediaQuery>
              <MediaQuery minWidth={615}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  href="/listings/new"
                  style={{
                    marginRight: "1rem",
                    borderRadius: "99px",
                    textTransform: "none",
                    fontFamily: "Nunito Sans",
                    fontWeight: "600",
                  }}
                >
                  + Add New Listing
                </Button>
                <div className={classes.editingBtnsContainer}>
                  {tab === "active" && !isEditing && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      style={{
                        marginRight: "1rem",
                        borderRadius: "99px",
                        textTransform: "none",
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        this.setState({
                          isEditing: !isEditing,
                          submitting: false,
                          discarding: false,
                        })
                      }
                    >
                      Edit Listings
                    </Button>
                  )}
                  {tab === "active" && isEditing && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="large"
                        style={{
                          marginRight: "1rem",
                          borderRadius: "99px",
                          textTransform: "none",
                          fontFamily: "Nunito Sans",
                          fontWeight: "600",
                          color: "white",
                          backgroundColor: "#00DE94",
                        }}
                        onClick={() => this.applyChanges()}
                      >
                        Apply Changes
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="large"
                        style={{
                          marginRight: "1rem",
                          borderRadius: "99px",
                          textTransform: "none",
                          fontFamily: "Nunito Sans",
                          fontWeight: "600",
                        }}
                        onClick={() => this.discardChanges()}
                      >
                        Discard Changes
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </MediaQuery>
            </div>
          </Grid>
          <Grid item xs={12} md={10}>
            {this.renderMain()}
          </Grid>
        </Grid>        
        {this.renderNewListingsPopup()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Listings);
