import React, { Component } from "react";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import moment from "moment";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SellerListingsTable from "../components/SellerListingsTable";
import getTimezone from "../libs/get-timezone";
import { kebabToTitleCase } from "../utils/general";
import sellerDesktopBanner from "../images/crowd-photo.jpg";
import sellerLargeBanner from "../images/crowd-photo.jpg";
import sellerXLargeBanner from "../images/crowd-photo.jpg";
import { styles as homeStyles } from "./styles";
import SearchAutocomplete from "../components/search-auto-complete";

const styles = (theme) => homeStyles(theme);

class SellerPage extends Component {
  constructor(props) {
    super(props);

    const url = window.location.pathname;
    const sellerSlug = url.split("/")[1];
    
    this.state = {
      sellerSlug: sellerSlug,
      isLoading: true,
      seats: null,
      activeSeats: null,
      lastEvaluatedKey: null,
      loadMoreLoading: false,
    };

    this.setSeats = this.setSeats.bind(this);
  }

  async setSeats(includeInactive = false) {
    const seats = await this.seats(includeInactive);
    if (!seats) {
      return;
    }
    const {
        activeSeats: activeSeatsState
      } = this.state;
    const seatLists = seats.listings;
    const arrangedSeats = _.orderBy(
      seatLists,
      ["gameDate", "zoneNo", "zone", "row", "price"],
      "asc"
    );
    const activeSeats = _.chain(arrangedSeats)
      .filter(
        (seat) =>
          !seat.isSold &&
          !seat.isArchived &&
          (!seat.gameDate ||
            seat.gameDate >=
              moment.tz(getTimezone(seat.game)).subtract(1, "hour").valueOf())
      )
      .orderBy(["gameDate", "gameName", "zoneNo", "zone", "row", "price"], "asc")
      .value();
      
    const preActiveState = activeSeatsState ? activeSeatsState : [];
    this.setState({
      ...this.state,
      seats: arrangedSeats,
      activeSeats: [...preActiveState, ...activeSeats],
      isLoading: false,
      loadMoreLoading: false,
      lastEvaluatedKey: seats.lastEvaluatedKey,
    });
  }

  async componentWillMount() {
    const seller = await this.seller();
    if (!seller.user) {
      this.props.history.push("/not-found");
    } else {
      this.setSeats();
    }
    
  }

  seller = () => {
    const sellerSlug = this.props.match.params.sellerSlug;
    return API.get("v2", `marketplace/users/by/sellerSlug/${sellerSlug}`);
  };

  componentDidMount() {
    const _bannerDesktop = sellerDesktopBanner;
    const _bannerLarge = sellerLargeBanner;
    const _bannerXLarge = sellerXLargeBanner;
    this.setState({
      bannerDesktop: _bannerDesktop,
      bannerLarge: _bannerLarge,
      bannerXLarge: _bannerXLarge,
    });
  }

  renderBanner() {
    const { classes } = this.props;
    const { sellerSlug, bannerDesktop } = this.state;
    const sellerName = kebabToTitleCase(sellerSlug);

    return (
      <div 
        className={classes.bannerContainer} 
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 160, 255, 0.9),  rgba(0, 160, 255, 0.9)), url(${bannerDesktop})`
        }}
      >
        <Grid
          md={12}
          className={classes.bannerTitleContainer}
        >
          <Typography
            color="primary"
            className={classes.bannerTitle}
          >
            {sellerName}
          </Typography>
          <div className={classes.bannerSearchContainer}>
            <SearchAutocomplete />
          </div>
        </Grid>
      </div>
    );
  }

  async seats(includeInactive = false) {
    const { lastEvaluatedKey, sellerSlug } = this.state;
    const response = await API.get(
      "v2",
      `marketplace/listings/by/paginated/sellerSlug/${
        sellerSlug
      }?includeInactive=${includeInactive}${
          lastEvaluatedKey ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}` : ""
      }`
    );
    return response;
  }

  renderMain() {
    return (
      <main>
        <Grid container>
          <Grid item xs={12} md={12}>
            {this.renderListings()}
          </Grid>
        </Grid>
      </main>
    );
  }

  renderListings() {
    const {
      isLoading,
      activeSeats,
      lastEvaluatedKey
    } = this.state;
    
    if (isLoading) {
      return (
        <React.Fragment>
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          >
            <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading listings...
          </Typography>
        </React.Fragment>
      );
    }
    return (
    <React.Fragment>
        <SellerListingsTable
            seats={activeSeats ? activeSeats : null}
        />
        {lastEvaluatedKey && (
        <Button
            onClick={() => {
            this.setState({ ...this.state, loadMoreLoading: true });
            this.setSeats(false);
            }}
            disabled={this.state.isLoading || this.state.loadMoreLoading}
            variant="contained"
            style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            color="secondary"
        >
            {this.state.isLoading || this.state.loadMoreLoading
            ? "Loading..."
            : "Load More"}
        </Button>
        )}
    </React.Fragment>
    );

  }

  render() {
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>Listings</title>
          <meta name="description" content="Ticket listings by sellers" />
          <meta name="keywords" content={`listing, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Listings" />
          <meta property="og:description" content="Ticket listings by sellers" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderBanner()}
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            {this.renderMain()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SellerPage);