import React, { useState } from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  withStyles,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";

const styles = (theme) => ({
  accountMenuContainer: {
    cursor: "pointer",
  },
  button: {
    minHeight: 48,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const accountMenu = ({ classes, currUser, handleLogout }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (e) => {
    setOpen(true);
  };
  const handleCloseOut = (e) => {
    setOpen(false);
  };
  const handleClose = (url) => {
    setOpen(false);
    window.location.href = url;
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        onClick={handleMenu}
        buttonRef={(node) => {
          setAnchorEl(node);
        }}
      >
        {currUser.name}
        <AccountCircle className={classes.rightIcon} />
      </Button>

      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseOut}>
                <MenuList>
                  <MenuItem onClick={() => handleClose("/listings")}>
                    Listings
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/account")}>
                    Account Settings
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default withStyles(styles)(accountMenu);
