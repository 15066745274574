import React, { useEffect, useState } from "react";
import { Typography, withStyles } from '@material-ui/core';
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
    container: {
        padding: "20px 200px"
    },
    [theme.breakpoints.down("sm")]: {
        container: {
            padding: "80px 50px"
        },
    },
    image: {
        marginTop:"20px",
        marginBottom:"10px",
        objectFit:"cover",
        width:"100%",
        height:"400px",
        objectPosition: "50% 50%",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    }
})

const BlogPage = ({
    classes
}) => {
    const [content, setContent] = useState();
    const [blogUrl, setBlogUrl] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const url = window.location.pathname;
        const selectedBlog = url.split("/")[2];
        fetchUrl(selectedBlog);
    }, [])

    async function fetchUrl(name) {
        try {
          const presignedUrl = await API.get(
            "v2",
            `blogs/get/by/blogName/${name}`
          );
          setBlogUrl(presignedUrl)
          try {
            fetch(presignedUrl)
            .then(response => response.json())
            .then(data => {
              setContent(data);
              setLoading(false)
            });
          } catch (error) {
            console.log(error)
          }
        } catch (err) { console.log('error fetching blog URL: ', err) }
      }

    function renderElement(element){
        const DOMPurify = require('dompurify')(window);
        if (element[0] === 'h1'){
            return <h1 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element[1])}}></h1>
        } else if (element[0] === 'h2'){
            return <h2 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element[1])}}></h2>
        } else if (element[0] === 'h3'){
            return <h3 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element[1])}}></h3>
        } else if (element[0] === 'p'){
            return <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element[1])}}></p>
        } else if (element[0] === 'time'){
            return <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(element[1])}} style={{paddingBottom:"20px"}}></span>
        } else if (element[0] === 'picture'){
            var source = DOMPurify.sanitize(element[1]);
            var alt = "";
            if (element.length === 3) {
                alt = DOMPurify.sanitize(element[2]);
            }
            return <img src={`${source}`} alt={alt} className={classes.image}></img>
        } else if (element[0] === 'ol'){
            const list = element[1].join("");
            return <ol dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}} style={{paddingBottom:"20px"}}></ol>
        } else if (element[0] === 'ul'){
            const list = element[1].join("");
            return <ol dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}} style={{paddingBottom:"20px"}}></ol>
        } else {
            return null
        }
    }

    function renderMeta(element, index){
        const DOMPurify = require('dompurify')(window);
        if (element[0] === 'h1'){
            const metaTitle = DOMPurify.sanitize(element[index]);
            const metaDescription = DOMPurify.sanitize(element[index]);
            const currentURL = window.location.href;

            return (
                <Helmet key={`meta-${index}`}>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={`blogs, games, tickets, buy tickets, no fees, buy cheap NHL/NBA/MLB/CFL tickets`} />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentURL} />
                    <link rel="canonical" href={currentURL} />
                </Helmet>
            )
        }
    }

    if (loading) {
        return (
            <React.Fragment>
                <Typography
                    align="center"
                    variant="body2"
                    style={{ marginTop: "2rem" }}
                >
                    <CircularProgress
                    color="inherit"
                    style={{ width: 16, height: 16, marginRight: 16 }}
                    />
                    Loading blog...
                </Typography>
            </React.Fragment>
        )
    }
    return (
        <div className={classes.container}>
            {content.map((element, index) => (
                [
                    renderMeta(element, index),
                    renderElement(element)
                ]
            ))}
        </div>
    )
}
export default withStyles(styles)(BlogPage);
