const commonConfig = {
  TE_FEE: 15,
  TE_FEE_TORONTO_BLUE_JAYS: {
    '2023-07-28': 3,
  }
};

const elavonConfig = {
  ELAVON_PURCHASE_CURRENCY: "124", // ISO-4217 three-digit numeric currency code. Required for messageCategory = 01. Required for messageCategory = 02 if 3DS Requestor Authentication Indicator = 02 or 03.
  ELAVON_PURCHASE_EXPONENT: "2", // Minor units of currency as specified in the ISO-4217 currency exponent. For example, 2 for USD, 0 for Yen, etc. Required for messageCategory = 01. Required for messageCategory = 02 if 3DS Requestor Authentication Indicator = 02 or 03.
  ELAVON_MESSAGE_CATEGORY: "01", // Identifies the authentication type used in the transaction. 01- Payment authentication; 02 – Non payment authentication.
  ELAVON_TRANS_TYPE: "01", // Identifies the type of transaction being authenticated.
  ELAVON_3DS_REQUEST_OR_AUTHENTICATION_IND: "01", // Indicates the type of authentication request.
  ELAVON_3DS_REQUESTOR_CHALLENGE_IND: "04", // Indicates whether a challenge is requested for this transaction.
};

export default {
  ...commonConfig,
  ...elavonConfig,
};
