import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../images/logo.png";
import {
  Drawer,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Typography,
  Hidden,
  withWidth,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeRounded";
import GavelIcon from "@material-ui/icons/GavelRounded";
import TicketIcon from "@material-ui/icons/ConfirmationNumberRounded";
import DescriptionIcon from "@material-ui/icons/DescriptionRounded";
import ListIcon from "@material-ui/icons/ListRounded";
import { compose } from "recompose";

const styles = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    logoImage: {
      width: "141px",
      height: "34px",
      // marginLeft: -68,
    },
  },
  [theme.breakpoints.up("md")]: {
    logoImage: {
      marginLeft: 68,
      height: "3.5rem",
    },
  },
  root: {
    flexGrow: 1,
  },
  logo: {
    margin: "0 auto",
    position: "relative",
  },
  link: {
    color: [theme.palette.text.primary],
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      achorEl: null,
      open: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  toggleDrawer() {
    const { isDrawerOpen } = this.state;
    this.setState({ isDrawerOpen: !isDrawerOpen });
  }

  renderDrawer() {
    const { classes } = this.props;

    return (
      <Drawer open={this.state.isDrawerOpen} onClose={this.toggleDrawer}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => this.toggleDrawer()}
          onKeyDown={() => this.toggleDrawer()}
        >
          <div className={classes.list}>
            <List style={{ backgroundColor: "#2faaf3"}}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Home
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/calgary-flames/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Flames Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/winnipeg-jets/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Jets Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/edmonton-oilers/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Oilers Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/vancouver-canucks/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Canucks Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/toronto-maple-leafs/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Maple Leafs Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/ottawa-senators/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Senators Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/toronto-raptors/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Raptors Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/toronto-blue-jays/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Blue Jays Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link
                to="/bc-lions/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Lions Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link
                to="/edmonton-elks/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Elks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>


              <Link
                to="/calgary-stampeders/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Stampeders Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link
                to="/winnipeg-blue-bombers/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Blue Bombers Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link
                to="/toronto-argonauts/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Argonauts Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/ottawa-redblacks/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Redblacks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/saskatchewan-roughriders/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Roughriders Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/hamilton-tigercats/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Tiger-Cats Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/grey-cup/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Grey Cup Tickets
                      </Typography>
                    }
                  />
                </ListItem>
                <Link
                to="/calgary-wranglers/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Wranglers Tickets
                      </Typography>
                    } />
                </ListItem>
              </Link>
              </Link>
              <Link to="/sell" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Sell Tickets
                      </Typography>
                    }/>
                </ListItem>
              </Link>
              {/* <Link to="/sth" style={{ textDecoration: 'none' }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon />
                  </ListItemIcon>
                  <ListItemText primary="Season Ticket Holders Forum" />
                </ListItem>
              </Link> */}
              <Link to="/legal" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <GavelIcon style={{color: "#FFFFFF",}}  />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Legal
                      </Typography>
                    } />
                </ListItem>
              </Link>
              <Link to="/why-us" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <DescriptionIcon  style={{color: "#FFFFFF",}}  />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        About FansFirst
                      </Typography>
                    }/>
                </ListItem>
              </Link>
              <Link to="/faqs" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon style={{color: "#FFFFFF",}} />
                  </ListItemIcon>
                  <ListItemText primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        FAQs
                      </Typography>
                    } />
                </ListItem>
              </Link>
            </List>
          </div>
        </div>
      </Drawer>
    );
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget, open: true });
  }

  handleClose(url) {
    this.setState({ anchorEl: null, open: false });
    this.props.history.push(url);
  }

  logo() {
    const { isAuthenticated, classes, width } = this.props;

    if (isAuthenticated) {
      return (
        <img
          src={Logo}
          alt="FansFirst Logo"
          height="32"
          className={classes.logoImage}
          style={{ marginLeft: width === "xs" ? 0 : 68 }}
        />
      );
    } else {
      return (
        <img
          src={Logo}
          alt="FansFirst Logo"
          height="32"
          className={classes.logoImage}
          style={{ marginLeft: -68 }}
        />
      );
    }
  }

  render() {
    const { classes, isAuthenticated, currUser } = this.props;
    const { open, anchorEl } = this.state;

    return (
      <React.Fragment>
        <AppBar position="static" color="default" className={classes.MuiAppBar}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => this.toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logo}>
              <Link to="/">{this.logo()}</Link>
            </div>
            {isAuthenticated && currUser && (
              <div>
                <div>
                  <Hidden smDown>
                    <Typography
                      variant="body2"
                      style={{ display: "inline-flex" }}
                      onClick={this.handleMenu}
                    >
                      {currUser.name}
                    </Typography>
                  </Hidden>
                  <IconButton
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={() => this.handleClose("/listings")}>
                    Listings
                  </MenuItem>
                  <MenuItem onClick={() => this.handleClose("/account")}>
                    Account Settings
                  </MenuItem>
                  <MenuItem onClick={() => this.props.handleLogout()}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
          {this.renderDrawer()}
        </AppBar>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles), withWidth())(Nav);
