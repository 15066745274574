import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    formContainer: {
      margin: "5rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      marginTop: "3rem",
      padding: "0 1rem",
    },
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  button: {
    padding: ".875rem",
    marginTop: ".5rem",
  },
  successMessage: {
    fontSize: "1.25rem",
    fontWeight: 'bold',
    marginTop: "1rem",
    marginBottom: "1rem",
    color: "#4caf50",
  },
});

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(this.props.location.search)
    const key = urlParams.get('confirmation_code')
    this.state = {
      code: key,
      isValidCode: true,
      isSubmitting: false,
      isVerified: false,
      isResending: false,
      isResent: false,
      checkoutAlert: false,
      alertTitle: "",
      alertContent: "",
      refreshURL: "",
    };

    this.toggleCheckoutAlert = this.toggleCheckoutAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();
    let old_email = user.attributes['custom:old_email'];
    let email_verified = user.attributes.email_verified;
    
    if ( old_email == null && !email_verified){
      this.resendCode();
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;

    if (code === "") {
      this.setState({ isValidCode: false, isResent: false });
      return;
    }

    try {
      this.setState({
        isSubmitting: true,
        isResent: false,
      });
       
      await Auth.verifyCurrentUserAttributeSubmit("email", code);

      this.setState({
        isSubmitting: true,
        isVerified: false,
      });      

    } catch (e) {
      if (e.code === "CodeMismatchException") {
        this.setState({
          isSubmitting: false,
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent: "Invalid code. Please try again.",
          refresh: false
        });
      } else if(e.code === "ExpiredCodeException") {
        this.setState({
          isSubmitting: false,
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent: "Expired code. Please request new code.",
          refresh: false
        });
      } else {
        this.setState({
          isSubmitting: false,
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent: "Sorry, something went wrong. Please try again later.",
          refresh: false
        });
      }
      return;
    }

    try {
      this.setState({
        isSubmitting: true,
        isResent: false,
      });

      let localFormat = "";
      const { currUser } = this.props;
      const user = await Auth.currentAuthenticatedUser();
      let old_email = user.attributes['custom:old_email'];
      if ( old_email == null ){
        old_email = currUser.email;
      }
      const phoneNumber = parsePhoneNumberFromString(currUser.phone_number);
      localFormat = phoneNumber.formatNational().replace(/[^\d]+/g, "");        
      
      await API.post("v2", "marketplace/accounts/update", {
        body: {
          email: old_email,
          new_email: currUser.email,
          new_name: currUser.name,
          new_phone: localFormat !== "" ? `+1${localFormat}` : ""
        },
      });

      this.setState({
        isSubmitting: false,
        isVerified: true,
        checkoutAlert: true,
        alertTitle: "Success",
        alertContent: "Your email is now verified. Click Okay to view your profile",
        refresh: true,
        refreshURL: `/account`,
      }); 
    } catch (e) {
      this.setState({
        isSubmitting: false,
        checkoutAlert: true,
        alertTitle: "Success",
        alertContent: "Your email is now verified. Click Okay to view your profile",
        refresh: true,
        refreshURL: `/account`,
      });

    }
  }

  async resendCode(e) {
    try {
      this.setState({
        isResending: true,
      });

      await Auth.verifyCurrentUserAttribute("email");

      this.setState({
        isResending: false,
        isResent: true,
      });
    } catch (e) {

      this.setState({
        isResending: false,
        isResent: true,
      });
      alert("Sorry, something went wrong. Please try again later.");
    }
  }

  renderForm() {
    const { classes, currUser } = this.props;
    const { isSubmitting, isResent, isResending, isVerified } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography
          style={{
            marginBottom: "1rem",
          }}
          variant="title"
          color="textPrimary"
        >
          Verify Your Email
        </Typography>
        <Typography variant="caption">
          Please check your email ({currUser.email}) for the
          verification code.
        </Typography>
        {/* <Typography variant="caption" style={{ marginBottom: "1.5rem" }}>
          <a
            href="/account"
            style={{ color: "#2196f3", textDecoration: "none" }}
          >
            Click here to update your email
          </a>
          .
        </Typography> */}

        {isResent && (
          <Typography
            variant="caption"
            align="center"
            className={classes.successMessage}
          >
            We have sent a verification code to your email. Please enter it
            below.
          </Typography>
        )}
        <TextField
          autoFocus
          className={
            this.state.isValidCode ? classes.textField : classes.textFieldError
          }
          disabled={isSubmitting || isVerified}
          error={true}
          id="code"
          placeholder="Verification Code"
          name="code"
          fullWidth={true}
          value={this.state.code}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />

        {this.state.isValidCode ? (
          ""
        ) : (
          <FormHelperText error={true}>
            Please enter the code that we sent to your mobile no.
          </FormHelperText>
        )}

        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting || isVerified}
          variant="contained"
          fullWidth={true}
          onClick={this.handleSubmit}
          type="submit"
        >
          Submit
        </Button>
        <Button
          disableFocusRipple={true}
          className={classes.button}
          disabled={isResending || isVerified}
          variant="text"
          fullWidth={true}
          onClick={this.resendCode}
        >
          Resend Code
        </Button>
        {isVerified && (
          <Typography
            variant="caption"
            align="center"
            className={classes.successMessage}
          >
            Your email is now verified.{" "}
            <a href="/listings" style={{ color: "#4caf50" }}>
              Click here to go back to your listings
            </a>
            .
          </Typography>
        )}
      </form>
    );
  }

  render() {
    const { classes, currUser } = this.props;

    return (
      <div>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            {!currUser.email_verified && this.renderForm()}
            {currUser.email_verified && (
              <React.Fragment>
                <Typography
                  style={{
                    marginBottom: "1.5rem",
                  }}
                  variant="title"
                  color="textPrimary"
                >
                  Your email is already verified.
                </Typography>
                <Typography variant="caption">
                  <a href="/listings" style={{ color: "#4caf50" }}>
                    Click here to go back to your listings
                  </a>
                  .
                </Typography>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        {this.renderCheckoutAlert()}
      </div>
    );
  }

  toggleCheckoutAlert() {
    const { refresh, refreshURL } = this.state;
    this.setState({ checkoutAlert: !this.state.checkoutAlert });
    if (refresh) {
      this.props.history.push(refreshURL);
    }
  }

  renderCheckoutAlert() {
    const { checkoutAlert, alertTitle, alertContent } = this.state;
    return (
      <Dialog open={checkoutAlert} onClose={this.toggleCheckoutAlert}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertContent}</DialogContent>
        <DialogActions>
          <Button onClick={this.toggleCheckoutAlert} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EmailVerification);
