import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import "./index.css";
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
const renderReviewsIframe = (url) => {
  var fullUrl = `https://www.facebook.com/plugins/post.php?href=${url[0]}&show_text=true&width=auto&height=150`;
  return (
    <div className="iframe-container">
      <iframe
        src={fullUrl}
        title="testimony"
        className="iframe"
        paddingleft="15%"
        paddingright="15%"
        height="100%"
        width="100%"
        maxwidth="450px"
        maxheight="235px"
        display="block"
        scrolling="yes"
        frameBorder="0"
        allow="fullscreen; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};
// old implementation
// const renderReviewsIframe = (url) => {
//   var fullUrl = `https://www.facebook.com/plugins/post.php?href=${url}&width=auto&show_text=true&appId=694091671087246&height=150`;
//   return (
//     <div className="iframe-container">
//       <iframe
//         title="testimony"
//         className="iframe"
//         src={fullUrl}
//         paddingleft="15%"
//         paddingright="15%"
//         height="100%"
//         width="100%"
//         maxwidth="450px"
//         maxheight="235px"
//         display="block"
//         scrolling="yes"
//         frameBorder="0"
//         allow="fullscreen; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//       ></iframe>
//     </div>
//   );
// };

// Stores review URLs and whether they have already been rendered. Second property is used to optimize lazy loading.
var reviewUrls = [
  [
    "https%3A%2F%2Fweb.facebook.com%2FAnto.M.Chan%2Fposts%2Fpfbid021JnAJfEEVFDhGoMjx7pKVMRx5tPt7N8P8M5HJ7qXKrVk5EaLW63EKCnjT9zVEytBl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fmichael.dumka%2Fposts%2Fpfbid0h9EHpkwWM3tp2Kxa6iQPigxwgcvzmVQtMqfD3BK4TYFxymcJwmCVd9DFtX8eNEihl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fdani.bessette.8%2Fposts%2Fpfbid033XyShCBTKKrD6q6gBT4xUDepfn8cUFQAWGvwXwSfqfQvJvwtZoVdgghxC3GhSEMRl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fchris.jermyn%2Fposts%2Fpfbid0yES1dVi6d3xg4FX78DBAwYZzs8P9zDK4kbSBHd8G6BxpZ1BG5q3jGZBxZ5WyKcMUl",
    false
  ], 
  [
    "https%3A%2F%2Fweb.facebook.com%2Fkatia.perron.1%2Fposts%2Fpfbid02vLMWrC5HER8GwhH8uEgzx7Z3R7pti2tPKgKN9DpcVT3qmFSTG96HYbQeg2uuxcnul",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fcasey.toews.9%2Fposts%2Fpfbid0qZqR4Fz62jZBTcCeqhPvF1LB5FbmEm6gdHmr8JjPg2yzxfSuqXmAeXCS4t6yNWzkl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fchrisle9%2Fposts%2Fpfbid0rBz4CaSbASfcZU2Bw4kCMMPwPovStxnAVZ2aztMaLRp5oBB38i5PYb5donJPtvhwl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Falexander.bell.7906%2Fposts%2Fpfbid0TV8Y5kQYEz17QbwaCXDz8ee3vQipBPP1FWdZkRZFNF7NquYUqAyBSU1EfCwNdRbRl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fsahota1.jas%2Fposts%2Fpfbid024LW1rASDGkEmZ43FKjoa6MU73FDh2h6yEzi31zCL5rKqXqNXkJgXTX4kfL8om5Tel",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fbrock.mansoff%2Fposts%2Fpfbid026UtdRmSQr6RQUp2yKPGGcjTdyNSpu9DM24rKYXQ49Dv7yQwicCUjPaxMWBYXgKYKl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Frooven.maharaj%2Fposts%2Fpfbid0P5gshi5gnLyviYTAWd2iMwyJc1eaFD4mFsUMkZRo9EsNgsRsX9SN9noBc1xdkpTql",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fnick.czuba%2Fposts%2Fpfbid0XhenBXFvribPzoabWkGUpd8aNu4ocB65Mh3jPEdZrM9WfqHQrznCEHHHLwhpTnT5l",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Ftravis.dyck.16%2Fposts%2Fpfbid0E1d6jTXJpssWSun7oaBUzeWUkoTkAx7iippwYYdM9SmBmmvhJKKguBmR3EzGotNwl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fbob.mellin.18%2Fposts%2Fpfbid0FTpnJx4eSUdfMx4bAz3iAdCcmCwwU8czdgNuNqsdMSnXzjdaXE4KJK5tvUTDmpVrl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fnatalie.rietze%2Fposts%2Fpfbid02eekCeM5v3DnMoDUmJhmKYh6MYjCxMAV8UZrDVQBvxUWXUGjJSiuTccRrp6WuAPxul",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fdan.jefkins%2Fposts%2Fpfbid02xTmJ2cmDL2KzZzSEbuCScnLyxzqBdKiKPeU9ZFLRhKHjvGgqtWrtEDD855Zjnty8l",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fnicole.smithmarshall%2Fposts%2Fpfbid0T9355hB7G3T1gNebzrTMKu5xPX1R3UHKF6ywqQ3ZGPg169Q9G7mNmQsZ4NqVgyoTl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Ffan2391%2Fposts%2Fpfbid0sNa2LnyTY4pTVW8ZVY89kmsWLPwanCs9WWERJCK1BjFJ3bHbGd1igupxfRnwWHGLl",
    false
  ],
  [
    "https%3A%2F%2Fweb.facebook.com%2Fakonotopsky%2Fposts%2Fpfbid024GZxdddE1ZRqbKzTN7ptNqHHnC6KPGvAz4L2oCK3A1eSBSw8Sre68EciVYsGyLepl",
    false
  ],
];
// var reviewUrls = [
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fcasey.toews.9%2Fposts%2Fpfbid0qNL88ix2dQGov6BtmoYwxn1gnCEyhJ9R6uSybfT4PXzXjCaUAqMk1fqgtB7cnpjbl",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fchrisle9%2Fposts%2Fpfbid0rUSHqgyjagHRtkKjXPFSv1fXc7DjGyk6wxEzjh7wKXhNB3AobLkz5XsU5dfX1Nnsl",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Falexander.bell.7906%2Fposts%2Fpfbid02X1RSNXGaWDsbBTj7maAowzuXMHu3GF55XBNx7H8RoidQwRwGf4E4D8JmfySJ5Tkil",
//     false,
//   ],
//   // [
//   //   "https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0USgK2Uabf4wkZ4qiZaN2Xc9fGw1nFa7nxvG2dJdVpXMZR1EGQWv7pTgWfVhSNQXHl%26id%3D100070202763828",
//   //   false,
//   // ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fsahota1.jas%2Fposts%2Fpfbid024VJjWuwdWZFJfZaxXU2aGYtvNrAWAQGksMf2dTD17m65fBYqRNUYV6XS2JZhtKQAl",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fbrock.mansoff%2Fposts%2Fpfbid02c6dchkGQhD44uxhqdD7Ahq4kLVmGxCe19ZSRBz2Fvux6WrGF25BVvaKYSLJrTZZl",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Frooven.maharaj%2Fposts%2Fpfbid02SbymzUwj3rdYYvV6pZBd94kN1tSveuvD37AHUWwVm5gcYf2zo8xsha46D9YcTQdfl",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fnick.czuba%2Fposts%2Fpfbid02bDwgUJ7y8LmtrX27USFkqPM8SiidQUv6GXxY8JnviRAiFV4LNeaDpkaYRkLWinaCl",
//     false,
//   ],
// ];
// old reviews data
// var reviewUrls = [
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fnick.czuba%2Fposts%2F10165870662690577",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Ftravis.dyck.16%2Fposts%2F694614660470",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fbob.mellin.18%2Fposts%2F1277831885996518",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fjeri.copeland%2Fposts%2F10157563385452060",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fdan.jefkins%2Fposts%2F10156647622640855",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fconnie077%2Fposts%2F10221202371719649",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fnatalie.rietze%2Fposts%2F2665987200103467",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fakonotopsky%2Fposts%2F10162874339300360",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Ffan2391%2Fposts%2F2767444033274284",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fmboggles55%2Fposts%2F539645389911907",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fshawbeck%2Fposts%2F10156351552671850",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fdeclan.hughes.549%2Fposts%2F2481211628766906",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fstevenvbui%2Fposts%2F1577501652374898",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Flogan.bandy%2Fposts%2F2194769890568741",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fjonathan.mcmaster.18%2Fposts%2F10157106167595050",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fmihajlo.andjelko12%2Fposts%2F10161468630790534",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fjesse.westman%2Fposts%2F10161380285905386",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D774572819608575%26id%3D100011675922841",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2FAnoop.Kah%2Fposts%2F10215969740669684",
//     false,
//   ],
//   [
//     "https%3A%2F%2Fwww.facebook.com%2Falex.reid.1829%2Fposts%2F10161241304540384",
//     false,
//   ],
// ];
// Randomly sort the array of review urls.
reviewUrls = shuffle(reviewUrls);

const WhatCustomerSayV2 = () => {
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState("prev");
  const length = reviewUrls.length;
  const lazyLoad = 2;

  const nextReview = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    setDirection("next");
  };

  const prevReview = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    setDirection("prev");
  };

  return (
    <div className="container">
      <Typography
        variant="headline"
        align="center"
        color="error"
        style={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          margin: "0",
          fontSize: "2rem",
          fontWeight: "bold",
          backgroundColor: "#C9EBFF",
        }}
      >
        <span
          style={{
            color: "#00A0FF",
            whiteSpace: "nowrap",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          WHAT CUSTOMERS SAY
        </span>
        <br />
      </Typography>

      <div className="slider">
        {reviewUrls.map((slide, index) => {
          if (
            reviewUrls[index][1] == true ||
            index - current < lazyLoad ||
            length - index - current < lazyLoad
          ) {
            reviewUrls[index][1] = true;
            var disabledClass =
              direction === "next"
                ? "review inactive next"
                : "review inactive prev";
            var activeClass =
              direction === "next"
                ? "review active next"
                : "review active prev";
            return (
              <div
                className={index === current ? activeClass : disabledClass}
                key={index}
              >
                {renderReviewsIframe(slide)}
              </div>
            );
          }
        })}
      </div>

      <div className="prevNext">
        <a className="fas fa-chevron-left" onClick={prevReview}></a>
        <a className="fas fa-chevron-right" onClick={nextReview}></a>
      </div>
    </div>
  );
};
export default WhatCustomerSayV2;
