import React, { Component } from "react";
import querySearch from "stringquery";
import _ from "lodash";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import Helmet from "react-helmet";
import { withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import SeatsFilter from "../components/SeatsFilter";
import SeatsList from "../components/SeatsList";
import SeatsListDesktop from "../components/SeatsListDesktop";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import chair from "../images/chair.jpg";
import TextLogoWhite from "../images/text-logo-white.png";
import { Hidden, Button } from "@material-ui/core";

import SaddledomeSeatMap from "../components/SaddledomeSeatMap";
import JetsSeatMap from "../components/JetsSeatMap";
import OilersSeatmap from "../components/OilersSeatmap";
import CanucksSeatmap from "../components/seatmaps/canucks";
import MapleLeafsSeatMap from "../components/MapleLeafsSeatMap";
import SenatorsSeatMap from "../components/SenatorsSeatMap";
import RaptorsSeatMap from "../components/RaptorsSeatMap";
import {includeTeListingsCheck, rateFallback, getTeamId, getStaticTeID, getTEPlayoffID, getNormalizedTEData, getLocalizedDateTime } from "../libs/shared/seat-helpers";
import BlueJaysSeatMap from "../components/BlueJaysSeatMap";
import ElksSeatMap from "../components/ElksSeatMap";
import BlueBombersSeatMap from "../components/BlueBombersSeatMap";
import ArgonautsSeatMap from "../components/ArgonautsSeatMap";
import StampedersSeatMap from "../components/StampedersSeatMap";
import RedblacksSeatMap from "../components/RedblacksSeatMap";
import LionsSeatMap from "../components/LionsSeatMap";
import RoughridersSeatMap from "../components/RoughridersSeatMap";
import WranglersSeatMap from "../components/WranglersSeatMap";
import MooseSeatMap from "../components/MooseSeatMap";
import WhitecapsSeatMap from "../components/WhitecapsSeatMap";
import TorontoFcSeatMap from "../components/TorontoFcsSeatMap";
import AbbotsfordCentreSeatMap from "../components/AbbotsfordCanucksSeatMaps";

import LoadingDialog from "../components/shared/LoadingDialog";
import { formatTEToExclude, getFormattedTime, getTimeAtLabels } from "../libs/shared/grid-helper";
import { blueJaysMergeSectionZone, testDataCheck } from "../libs/shared/seat-helpers";
import SpecialSeatMapSelector from "../components/SpecialSeatMapSelector";
import TigercatsSeatMap from "../components/TigercatsSeatMap";

const styles = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    heroImage: {
      height: 200,
    },
  },
  [theme.breakpoints.up("md")]: {
    heroImage: {
      height: 300,
    },
    main: {
      padding: 0,
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
  },
  seatsListAppBar: {
    boxShadow: "none",
    top: "inherit",
    marginBottom: "1rem",
  },
  heroUnit: {
    marginBottom: ".5rem",
    position: "relative",
  },
  heroHeritageOilersUnit: {
    marginBottom: ".5rem",
    position: "relative",
    backgroundColor: "#f6feff",
  },
  heroImage: {
    width: "100%",
    backgroundImage: `url(${chair})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  leftIcon: {
    marginRight: ".5rem",
    fontSize: "1rem",
  },
  list: {
    height: "calc(100vh - 64px)",
    paddingTop: "2rem",
    overflowY: "auto",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  gameDetails: {
    marginBottom: "2rem",
  },
  seatMap: {
    maxHeight: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    padding: "2rem 0 5rem",
    position: "relative",
  },
  seatHeritageOilerMap: {
    maxHeight: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    padding: "2rem 0 5rem",
    position: "relative",
    backgroundColor: "#f6feff",
  },
  torontoSeatMap: {
    maxHeight: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    margin: "0rem 0 5rem",
    position: "relative",
  },
  seatMapImage: {
    height: "100%",
    width: "auto",
    margin: "0 auto",
    display: "flex",
    alignSelf: "center",
  },
});

class SellerSeats extends Component {
  constructor(props) {
    super(props);
    const storedRate = localStorage.getItem("currencyRate");
    const fxRateInit = storedRate ? JSON.parse(storedRate) : {timestamp: 1670452262, rate: rateFallback};
    
    this.state = {
      selectedZone: 0,
      zonesList: null,
      zoneSelectorOpen: false,
      filters: {
        noOfSeats: 0,
      },
      filterOpen: false,
      seats: [],
      filteredSeats: null,
      game: null,
      isLoading: true,
      seatMapOpen: false,
      showWarningDialog: false,
      teEvents: [],
      teListings: [],
      normalizedTESeats: [],
      isSavingTEListing: false,
      isLoadingTESeats: false,
      currencyRate: fxRateInit,
      localStateTeId: '',
      loadLocalStateTeId: false,
      isAisleSeat: 0,
      isWheelchair: 0,
      isInstantDelivery: 0,
      sellerPageName: null,
      sellerSlug: null
    };

    this.toggleSeatMap = this.toggleSeatMap.bind(this);
  }

  tier = (zone) => {
    switch (zone) {
      case 1: {
        return "3";
      }
      case 2: {
        return "2";
      }

      case 3: {
        return "1";
      }
    }
  };

  _getCurrencyExchangeRate = async () => {
    await API.get("v2", `marketplace/ticketEvo/currencyRate`)
      .then((data) => {
        this.setState({ ...this.state, currencyRate: data.currencyRate });
        localStorage.setItem("currencyRate", JSON.stringify(data.currencyRate));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  _handleTETeamFetch = async () => {
    const { homeTeamSlug: teamSlug, date, timezone, isPlayoffs, playoffSequence } = this.state.game;
    this.setState({ ...this.state, isLoadingTESeats: true, isLoading: true, loadLocalStateTeId: true });
    const teamId = getTeamId(teamSlug);

    if (teamId === "") {
      this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
      return;
    }
    await this._getCurrencyExchangeRate().then(() => {
      API.get(
        "v2",
        `marketplace/ticketEvo/events/index/by/performerId/${teamId}`
      )
        .then((data) => {
          const { events } = data;
          this.setState({
            ...this.state,
            teEvents: events,
          });
          const gameDate = getLocalizedDateTime(date, timezone).stringDate;
          const isStaticIdExist = getStaticTeID({date, timezone, homeTeamSlug: teamSlug});
          const findGameXTE = events.find((teGame) => {
            const teGameDate = teGame.occurs_at.split("T")[0];
            return gameDate === teGameDate;
          });
          if (findGameXTE && !isPlayoffs) {
            this.setState({...this.state, localStateTeId: findGameXTE.id})
          }
          if (!findGameXTE && !isPlayoffs && isStaticIdExist === '') {
            this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
            return;
          }

          if (isStaticIdExist !== '') {
            this.setState({...this.state, localStateTeId: isStaticIdExist});
          }

          if (isPlayoffs){
            const playoffsTEId = getTEPlayoffID(teamSlug, playoffSequence);
            if (playoffsTEId) {
              this.setState({...this.state, localStateTeId: playoffsTEId});
            } else {
              this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
              return;
            }
          }
          
        })
        .catch((e) => {
          this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
          console.error(e);
        });
    }).catch((e) => {
      this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
      console.error(e);
    });
  };

  _handleTEGameFetch = async () => {
    const { teId: queryTeId } = querySearch(this.props.location.search);
    const teId = queryTeId ? queryTeId : this.state.localStateTeId;
    this.setState({ ...this.state, isLoadingTESeats: true, isLoading: true,  loadLocalStateTeId: true});
    const teListingObject = await API.get(
      "v2",
      `marketplace/ticketEvo/listings/index/by/eventId/${teId}?order_by=retail_price+ASC`
    ).catch((e) => {
      this.setState({ ...this.state, isLoadingTESeats: false, isLoading: false });
      console.error(e);
    });
    const ticket_groups = teListingObject && teListingObject.ticket_groups ? teListingObject.ticket_groups : [];
    const slicedListing = ticket_groups.filter(
      (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, this.state.game)
    );
    this.setState({
      ...this.state,
      isLoadingTESeats: false,
      isLoading: false,
      teListings: slicedListing,
    });
  };

  _handleStoreListings = async (selectedSeat, pathname) => {
    const {game} = this.state;
    const { teId: queryTeId } = querySearch(this.props.location.search);
    const teId = queryTeId ? queryTeId : this.state.localStateTeId;
    const teQuery = `?teId=${teId}`;
    this.setState({ ...this.state, isSavingTEListing: true });
    const composeZone = selectedSeat.sellerEmail === "te@fansfirst.ca" && game.homeTeamSlug === "calgary-stampeders" ? `${selectedSeat.zone} ${selectedSeat.zoneNo}` : selectedSeat.zone;
    API.post("v2", "marketplace/ticketEvo/create/listings", {
      body: { seats: [{...selectedSeat, zone: composeZone.trim(), zoneNo: Number(selectedSeat.zoneNo) || null }], user: "te@fansfirst.ca" },
    })
      .then((data) => {
        this.setState({ ...this.state, isSavingTEListing: false });
        localStorage.setItem("teSeatSplit", JSON.stringify(selectedSeat.teSeatSplit));
        this.props.history.push(
          `${pathname}/${data.seatId}${teQuery}&teListingId=${
            selectedSeat.teListingId
          }`
        );
      })
      .catch((e) => {
        this.setState({ ...this.state, isSavingTEListing: false });
        console.error(e);
      });
  };

  _mockingData = () => {
    const { game, teListings, seats, currencyRate, isAisleSeat, isWheelchair, isInstantDelivery } = this.state;
    const allMergedSeats = getNormalizedTEData(game, teListings, currencyRate, seats);
    const sorted = _.sortBy(
      allMergedSeats.allSeatsMerged,
      ["price", "zoneNo", "zone", "row"],
      "asc"
    );

    let { zone } = querySearch(this.props.location.search);
    zone = parseInt(zone, 10);
    const filteredSeats = this.filterSeatsByZone(sorted, this.tier(zone), isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);
    const gameTime = getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);

    this.setState({
      selectedZone: zone,
      filteredSeats,
      seats: sorted,
      game,
      isLoading: false,
      zonesList,
      normalizedTESeats: allMergedSeats.seatsMerged.normalizedTESeats,
      showWarningDialog: game.isTbd ? game.isTbd : false,
      teamName: game.homeTeam
    });

    return {
      seats: allMergedSeats.seatsMerged.normalizedTESeats,
      user: "te@fansfirst.ca",
    };
  };

  getZonesList(seats) {
    return _.chain(seats)
      .map((seat) => `${seat.zone} ${seat.zoneNo ? seat.zoneNo : ""}`.trim())
      .uniq()
      .sort()
      .value();
  }

  async componentWillMount() {
    try {
      const game = await this.game();
      const seller = await this.seller();
      if (
        moment.now() > moment(game.date).add(1, "hour").valueOf() &&
        !game.isTbd
      ) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      if (testDataCheck(game)) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      const seatsList = await this.seats();

      seatsList.map(function(seat) {
        if(seat.gameDetails.homeTeamSlug === 'edmonton-oilers') {
          seat.zone = seat.zone.replace(/Upper Zone/g, 'Upper Bowl').replace(/Lower Zone/g, () => {
            if ((seat.zoneNo >= 101 && seat.zoneNo <= 104) || (seat.zoneNo >= 118 && seat.zoneNo <= 121)) {
              return 'Lower Club';
            }
            return 'Lower Bowl';
          });
        }
      });
      const seats = seatsList.map((seat) => {
        const remainingTickets = seat.noOfSeats - seat.seatsSold;

        return { ...seat, ...{ noOfSeats: remainingTickets } };
      });

      const sorted = _.sortBy(seats, ["price", "zoneNo", "zone", "row"], "asc");
      
      let { zone } = querySearch(this.props.location.search);
      zone = parseInt(zone, 10);

      const { filters, isAisleSeat, isWheelchair, isInstantDelivery } = this.state;

      const filteredSeats = this.filterSeatsByZone(sorted, this.tier(zone), isAisleSeat, isWheelchair, isInstantDelivery);

      const zonesList = this.getZonesList(filteredSeats);
      const gameTime = getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);

      this.setState({
        selectedZone: zone,
        filteredSeats,
        seats: sorted,
        game,
        isLoading: false,
        zonesList,
        showWarningDialog: game.isTbd ? game.isTbd : false,
        teamName: game.homeTeam,
        sellerPageName: seller.user.sellerPageName,
        sellerSlug: seller.user.sellerSlug
      });
    } catch (e) {}
  }

  seatmap() {
    const { game, filteredSeats, zonesList } = this.state;

    if (!game) {
      return;
    }

    if (game.isSpecial) {
      return (
        <SpecialSeatMapSelector
          homeTeamSlug={game.homeTeamSlug}
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    }

    if (game.homeTeamSlug === "winnipeg-jets") {
      return (
        <JetsSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "edmonton-oilers") {
      return (
        <OilersSeatmap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "calgary-flames") {
      return (
        <SaddledomeSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "vancouver-canucks") {
      return (
        <CanucksSeatmap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "toronto-maple-leafs") {
      return (
        <MapleLeafsSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "ottawa-senators") {
      return (
        <SenatorsSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "toronto-raptors") {
      return (
        <RaptorsSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "toronto-blue-jays") {
      return (
        <BlueJaysSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onBlueJaysSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "edmonton-elks") {
      return (
        <ElksSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "winnipeg-blue-bombers") {
      return (
        <BlueBombersSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "toronto-argonauts") {
      return (
        <ArgonautsSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "calgary-stampeders") {
      return (
        <StampedersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "ottawa-redblacks") {
      return (
        <RedblacksSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "bc-lions" || game.homeTeamSlug === "grey-cup") {
      return (
        <LionsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "saskatchewan-roughriders") {
      return (
        <RoughridersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "hamilton-tigercats") {
      return (
        <TigercatsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "calgary-wranglers") {
      return (
        <WranglersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "manitoba-moose") {
      return (
        <MooseSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "vancouver-whitecaps-fc") {
      return (
        <WhitecapsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={this.onSeatmapItemClick}
        zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "toronto-fc") {
      return (
        <TorontoFcSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.homeTeamSlug === "abbotsford-canucks") {
      return (
        <AbbotsfordCentreSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    } else if (game.isNormalGame) {
      return (
        <SaddledomeSeatMap
          seats={filteredSeats}
          onSeatmapItemClick={this.onSeatmapItemClick}
          zonesList={zonesList}
        />
      );
    }
  }

  game = () => {
    const gameId = this.props.match.params.id;
    return API.get("v2", `marketplace/events/by/eventId/${gameId}`);
  };

  seller = () => {
    const sellerSlug = this.props.match.params.sellerSlug;
    return API.get("v2", `marketplace/users/by/sellerSlug/${sellerSlug}`);
  };

  seats = () => {
    const gameId = this.props.match.params.id;
    const sellerSlug = this.props.match.params.sellerSlug;
    return API.get("v2", `marketplace/listings/by/sellerSlug/eventId/${sellerSlug}/${gameId}`);
  };

  toggleSeatMap() {
    this.setState({
      seatMapOpen: !this.state.seatMapOpen,
    });
  }

  showZoneSelector = () => {
    this.setState({
      zoneSelectorOpen: true,
    });
  };

  filterSeatsByZone(seats, zone, isAisleSeat, isWheelchair, isInstantDelivery) {
    return seats.filter((seat) => {
      if (seat.tier === zone || zone === "0" || zone === undefined) {
        if((isAisleSeat) && (seat.isAisleSeat) && (parseInt(seat.seatsSold) === 0)) {
          return seat;
        }
        else if(isWheelchair && seat.isWheelchair) {
          return seat;
        }
        else if(isInstantDelivery && seat.isInstantDelivery) {
          return seat;
        } 
        else if(!isAisleSeat && !isWheelchair && !isInstantDelivery){
          return seat;
        }
      }
    });
  }

  filterSeatsByQtyArray(seats, quantity) {
    if (quantity === 0) {
      return seats;
    } else {
      return seats.filter((seat) => {
        if (!seat.quantityOptions) {
          return seat;
        } else if (seat.quantityOptions.length === 0) {
          return seat;
        } else if (quantity >= 1 && quantity <= 4) {
          if (seat.quantityOptions.includes(quantity)) {
            return seat;
          }
        } else if (quantity >= 5) {
          const maxSeatQuantity = Math.max(...seat.quantityOptions);
          if (maxSeatQuantity >= 5) {
            return seat;
          }
        } else {
          return null;
        }
      });
    }
  }

  filterSeats(seats, zone, qty, isAisleSeat, isWheelchair, isInstantDelivery) {
    const seatsFilteredByZone = this.filterSeatsByZone(seats, zone, isAisleSeat, isWheelchair, isInstantDelivery);
    const filteredByQtyArray = this.filterSeatsByQtyArray(
      seatsFilteredByZone,
      qty
    );

    return filteredByQtyArray;
  }

  onZoneFilter = (selectedZone) => {
    const { filters, seats, isAisleSeat, isWheelchair, isInstantDelivery } = this.state;
    this.setState({ selectedZone: selectedZone });

    const zone = this.tier(selectedZone);

    const filteredSeats = this.filterSeats(seats, zone, filters.noOfSeats, isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);

    this.setState({
      filteredSeats: filteredSeats,
      zonesList,
    });
  };

  onAisleSeatFilter = (isAisleSeat) => {
    const { filters, seats, isWheelchair, isInstantDelivery, selectedZone } = this.state;
    this.setState({ isAisleSeat: isAisleSeat });

    const zone = this.tier(selectedZone);

    const filteredSeats = this.filterSeats(seats, zone, filters.noOfSeats, isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);

    this.setState({
      filteredSeats: filteredSeats,
      zonesList,
    });
  };

  onWheelchairFilter = (isWheelchair) => {
    const { filters, seats, isAisleSeat, isInstantDelivery, selectedZone } = this.state;
    this.setState({ isWheelchair: isWheelchair });

    const zone = this.tier(selectedZone);

    const filteredSeats = this.filterSeats(seats, zone, filters.noOfSeats, isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);

    this.setState({
      filteredSeats: filteredSeats,
      zonesList,
    });
  };

  onInstantDeliveryFilter = (isInstantDelivery) => {
    const { filters, seats, isAisleSeat, isWheelchair, selectedZone } = this.state;
    this.setState({ isInstantDelivery: isInstantDelivery });

    const zone = this.tier(selectedZone);

    const filteredSeats = this.filterSeats(seats, zone, filters.noOfSeats, isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);

    this.setState({
      filteredSeats: filteredSeats,
      zonesList,
    });
  };

  handleFilterClose = (filters) => {
    const { seats, selectedZone, isAisleSeat, isWheelchair, isInstantDelivery } = this.state;

    const zone = this.tier(selectedZone);

    const filteredSeats = this.filterSeats(seats, zone, filters.noOfSeats, isAisleSeat, isWheelchair, isInstantDelivery);

    const zonesList = this.getZonesList(filteredSeats);

    this.setState({
      filteredSeats,
      zonesList,
    });

    this.setState({ filters, filterOpen: false, filteredSeats, zonesList });
  };

  onSeatmapItemClick = (selectedZones) => {
    const { filters, seats } = this.state;
    if (selectedZones.length === 0) {
      this.onZoneFilter(this.state.selectedZone);
      return;
    }
    
    const selectedZoneNumbers = _.map(selectedZones, zone => zone.zoneNo.toString());
    const filteredByZone = _.filter(seats, (seat) => {
      if (seat.zoneNo && selectedZoneNumbers.includes(seat.zoneNo.toString())) {
        return seat;
      } else if (!seat.zoneNo) {
        const zoneName = seat.zone.trim().split(" ");
        if (selectedZoneNumbers.includes(zoneName[zoneName.length - 1])) {
          return seat;
        }
        if (_.map(selectedZones, "zone").includes(seat.zone)) {
          return seat;
        }
      }
    });

    const filteredByQty = this.filterSeatsByQtyArray(
      filteredByZone,
      filters.noOfSeats
    );

    this.setState({
      filteredSeats: filteredByQty,
    });
  };

  onBlueJaysSeatmapItemClick = (selectedZones) => {
    const { filters, seats } = this.state;
    if (selectedZones.length === 0) {
      this.onZoneFilter(this.state.selectedZone);
      return;
    }
    
    const filteredByZone = _.filter(seats, (seat) => {      
      let seatZone = blueJaysMergeSectionZone(seat.zone);
      if (seat.zoneNo && _.map(selectedZones, "zoneNo").includes(seat.zoneNo.toString())) {
        if (_.map(selectedZones, "zone").includes(seatZone)) {
          return seat;
        }
      } else if (!seat.zoneNo) {
        const zoneName = seatZone.trim().split(" ");
        if (_.map(selectedZones, "zoneNo").includes(zoneName[zoneName.length - 1])) {
          return seat;
        }
        if (_.map(selectedZones, "zone").includes(seatZone)) {
          return seat;
        }
      }
    });

    const filteredByQty = this.filterSeatsByQtyArray(
      filteredByZone,
      filters.noOfSeats
    );

    this.setState({
      filteredSeats: filteredByQty,
    });
  };

  handleSeatSelection = (selectedSeat) => {
    const { pathname } = this.props.history.location;
    const removeSellerSlug = pathname.replace(/^\/[^\/]+/, '');
    if (selectedSeat.seatId) {
      this.props.history.push(`${removeSellerSlug}/${selectedSeat.seatId}`);
    } else {
      this._handleStoreListings(selectedSeat, removeSellerSlug);
    }
  };

  renderFilters() {
    const { classes } = this.props;

    return (
      <Grid
        container
        style={{
          marginBottom: "1.5rem",
        }}
      >
        <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
          {/* <Button
            variant="text"
            color="primary"
            size="small"
            className={classes.button}
            onClick={this.toggleSeatMap}
          >
            <MapIcon className={classes.leftIcon} />
            View Seat Map
          </Button> */}
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="text"
            color="default"
            size="small"
            className={classes.button}
            onClick={() => this.setState({ filterOpen: true })}
          >
            <FilterListRoundedIcon className={classes.leftIcon} /># of Tickets
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderBanner() {
    const { game, sellerPageName } = this.state;
    const { classes } = this.props;

    let gameDate;

    if (game) {
      const regularGameDate = `${moment
        .tz(game.date, game.timezone)
        .format("ddd, MMM D YYYY")}${getTimeAtLabels(game)}${getFormattedTime(moment
        .tz(game.date, game.timezone)
        .format("h:mm A"), game)}`;
      gameDate = game.isTbd ? "TBD" : regularGameDate;
    }

    const heritageOilers = game && game.homeTeamSlug === "edmonton-oilers" && game.isSpecial;
    const seatStyle = heritageOilers ? classes.heroHeritageOilersUnit : classes.heroUnit;

    return (
      <React.Fragment>
        <AppBar
          className={classes.seatsListAppBar}
          color="primary"
          position="static"
        >
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Back"
              onClick={() => {
                this.props.history.push(`/${sellerPageName}`);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <div
              style={{
                alignItems: "auto",
              }}
            >
              {game ? (
                <div>
                  <Typography variant="body2" color="inherit">
                    {sellerPageName} - {game.longName}
                  </Typography>
                  <Typography variant="caption" color="inherit">
                    {game.isSpecial && (
                      <React.Fragment>{game.venue}</React.Fragment>
                    )}
                    {gameDate}
                  </Typography>
                </div>
              ) : (
                <Typography variant="body2" color="inherit">
                  Loading Game...
                </Typography>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <div className={seatStyle}>{this.seatmap()}</div>
      </React.Fragment>
    );
  }

  renderSeatsWithSeatMap() {
    const { classes } = this.props;
    const { seats, filteredSeats, selectedZone, game, isLoading, loadLocalStateTeId } = this.state;
    const torontoLeafs = game && game.homeTeamSlug === "toronto-maple-leafs";
    const torontoRaptors = game && game.homeTeamSlug === "toronto-raptors";
    const heritageOilers = game && game.homeTeamSlug === "edmonton-oilers" && game.isSpecial;
    const seatStyle = heritageOilers ? classes.seatHeritageOilerMap : classes.seatMap;
    const isLoadTE = this._checkIsLoadTE();

    return (
      <div>
        <Grid container>
          <Grid item md={4} xl={3} className={classes.list}>
            {seats && game && !isLoading ? (
              <SeatsListDesktop
                seats={filteredSeats}
                onSeatSelection={this.handleSeatSelection}
                zone={selectedZone}
                zoneFilter={this.onZoneFilter}
                isLoading={this.state.isLoading}
                isLoadingTESeats={this.state.isLoadingTESeats}
                onQtyFilter={this.handleFilterClose}
                filters={this.state.filters}
                game={game}
                isAisleSeat={this.isAisleSeat}
                isWheelchair={this.isWheelchair}
                isInstantDelivery={this.isInstantDelivery}
                aisleSeatFilter={this.onAisleSeatFilter}
                wheelchairFilter={this.onWheelchairFilter}
                instantDeliveryFilter={this.onInstantDeliveryFilter}
              />
            ) : null}
            {isLoading && (isLoadTE || loadLocalStateTeId) && (
              <Typography
                variant="subheading"
                style={{
                  padding: "2rem",
                  fontWeight: "bold",
                  color: "#2196f3",
                }}
                align="center"
              >
                Loading Seats...
              </Typography>
            )}
          </Grid>
          <Grid
            item
            md={8}
            xl={9}
            className={
              torontoLeafs || (torontoRaptors && !game.isSpecial)
                ? classes.torontoSeatMap
                : seatStyle
            }
          >
            {this.seatmap()}
          </Grid>
        </Grid>
      </div>
    );
  }

  renderWarningDialog() {
    const { showWarningDialog, teamName } = this.state;

    return (
      <Dialog
        open={showWarningDialog}
        onClose={() => this.setState({ showWarningDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You Are Buying {teamName} Playoffs Tickets
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: ".875rem" }}
          >
            The date and time are all subject to change and will be
            updated as soon as the official schedule is release by the league.
            <br />
            <br />
            If the game you are interested in doesn't occur (the series ends
            early or the {teamName} don't make it to a particular round of the
            playoffs) then your transaction will be cancelled and you get a full
            refund.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ showWarningDialog: false })}
            color="primary"
            autoFocus
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _checkIsLoadTE = () => {
    const { teId } = querySearch(this.props.location.search);
    return teId && teId !== "";
  };

  render() {
    const {
      game,
      filteredSeats,
      selectedZone,
      isSavingTEListing,
      isLoading,
      loadLocalStateTeId,
      sellerPageName
    } = this.state;

    const { classes } = this.props;
    const isLoadTE = this._checkIsLoadTE();

    let gameDate;

    if (game) {
      const regularGameDate = `${moment
        .tz(game.date, game.timezone)
        .format("ddd, MMM D YYYY")}${getTimeAtLabels(game)}${getFormattedTime(moment
        .tz(game.date, game.timezone)
        .format("h:mm A"), game)}`;
      gameDate = game.isTbd ? "TBD" : regularGameDate;
    }
    const currentURL = window.location.href;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{game ? game.longName : "Available Seats"}</title>
          <meta
            name="description"
            content={game ? game.longName : "Available Seats"}
          />
          <meta name="keywords" content={`available seats, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={game ? game.longName : "Available Seats"} />
          <meta property="og:description" content={game ? game.longName : "Available Seats"} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Typography variant="headline" style={{display: 'none'}}>
          {game ? game.longName : "Available Seats"}
        </Typography>
        <Hidden mdUp>{this.renderBanner()}</Hidden>

        <Hidden smDown>
          <AppBar position="static">
            <Toolbar>
              <a href="/">
                <img
                  src={TextLogoWhite}
                  height="16px"
                  width="auto"
                  alt="FansFirst"
                  style={{ marginRight: "1.5rem" }}
                />
              </a>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Back"
                onClick={() => {
                  this.props.history.push(`/${game.homeTeamSlug}/games`);
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <div
                style={{
                  alignItems: "auto",
                }}
              >
                {game ? (
                  <div>
                    <Typography variant="body2" color="inherit">
                    {sellerPageName} - {game.longName}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                      {game.isSpecial && (
                        <React.Fragment>{game.venue}</React.Fragment>
                      )}
                      {gameDate}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body2" color="inherit">
                    Loading Game...
                  </Typography>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>

        <main className={classes.main}>
          <Hidden mdUp>{this.renderFilters()}</Hidden>

          <Hidden smDown>{this.renderSeatsWithSeatMap()}</Hidden>

          <Hidden mdUp>
            {this.state.seats && this.state.game && !isLoading ? (
              <SeatsList
                seats={filteredSeats}
                onSeatSelection={this.handleSeatSelection}
                zone={selectedZone}
                zoneFilter={this.onZoneFilter}
                isLoadingTESeats={this.state.isLoadingTESeats}
                isLoading={this.state.isLoading}
                game={this.state.game}
                isAisleSeat={this.isAisleSeat}
                isWheelchair={this.isWheelchair}
                isInstantDelivery={this.isInstantDelivery}
                aisleSeatFilter={this.onAisleSeatFilter}
                wheelchairFilter={this.onWheelchairFilter}
                instantDeliveryFilter={this.onInstantDeliveryFilter}
              />
            ) : null}
            {isLoading && (isLoadTE || loadLocalStateTeId) && (
              <Typography
                variant="subheading"
                style={{
                  padding: "2rem",
                  fontWeight: "bold",
                  color: "#2196f3",
                }}
                align="center"
              >
                Loading Seats...
              </Typography>
            )}
          </Hidden>
        </main>

        <SeatsFilter
          open={this.state.filterOpen}
          onClose={this.handleFilterClose}
          filters={this.state.filters}
        />

        {this.renderWarningDialog()}

        {isSavingTEListing && <LoadingDialog />}
      </div>
    );
  }

  componentDidMount() {
    const isLoadTE = this._checkIsLoadTE();
    localStorage.removeItem("teSeatSplit");

    if (isLoadTE) {
      this._getCurrencyExchangeRate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { teListings, game, currencyRate, localStateTeId } = this.state;
    const isLoadTE = this._checkIsLoadTE();

    if (isLoadTE && JSON.stringify(game) !== JSON.stringify(prevState.game)) {
      includeTeListingsCheck({
        isPlayoffs: game.isPlayoffs,
        date: game.date,
        homeTeamSlug: game.homeTeamSlug,
        timezone: game.timezone,
      }) && this._handleTEGameFetch();
    }
    if (localStateTeId && game && (localStateTeId !== prevState.localStateTeId)) {
      includeTeListingsCheck({
        isPlayoffs: game.isPlayoffs,
        date: game.date,
        homeTeamSlug: game.homeTeamSlug,
        timezone: game.timezone,
      }) && this._handleTEGameFetch();
    }
    if (!isLoadTE && JSON.stringify(game) !== JSON.stringify(prevState.game)) {
      includeTeListingsCheck({
        isPlayoffs: game.isPlayoffs,
        date: game.date,
        homeTeamSlug: game.homeTeamSlug,
        timezone: game.timezone,
      }) && this._handleTETeamFetch();
    }
    if (
      JSON.stringify(teListings) !== JSON.stringify(prevState.teListings) &&
      teListings.length > 0 &&
      game &&
      currencyRate
    ) {
      this._mockingData();
    }
  }
}

export default withStyles(styles)(SellerSeats);
