import React from "react";
import { Button, withStyles } from "@material-ui/core";

import AccountMenu from "./account-menu";

import Logo from "../../images/logo.png";

const styles = (theme) => ({
  navContainer: {
    position: "relative",
    boxShadow: "0 4px 6px 0 rgb(0 0 0 / 3%)",
    backgroundColor: "#fafafa",
    width: "100%",
    zIndex: "9",
  },
  navContainerMobile: {
    position: "relative",
    boxShadow: "0 4px 6px 0 rgb(0 0 0 / 3%)",
    backgroundColor: "#fafafa",
    width: "100%",
    zIndex: "999",
  },
  nav: {
    maxWidth: 1366,
    margin: "0 auto",
    display: "flex",
  },
  logoContainer: {
    position: "relative",
    padding: theme.spacing.unit * 2,
    flex: 1,
  },
  logo: {
    // height: theme.spacing.unit * 5,
    width: "179px",
    height: "44px",
  },
  linksContainer: {
    display: "flex",
    padding: theme.spacing.unit * 2,
    flex: 1,
    verticalAlign: "middle",
  },
  navLinks: {
    flex: 1,
    minHeight: theme.spacing.unit * 5,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: `${theme.spacing.unit * 4}px`,
  },
  accountLinksContainer: {
    flex: 1,
    minHeight: theme.spacing.unit * 5,
    borderLeft: "1px solid #BFBFBF",
    paddingLeft: `${theme.spacing.unit * 4}px`,
  },
  button: {
    minWidth: theme.spacing.unit * 12,
    "&:first-child": {
      marginRight: `${theme.spacing.unit * 2}px`,
    },
  },
  loginButton: {
    minWidth: theme.spacing.unit * 12,
    margin: `0 ${theme.spacing.unit * 2}px`,
    backgroundColor: "#00a0ff",
    color: "white",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#0098f3",
    },
    textTransform: "none",
  },
  sellButton: {
    minWidth: theme.spacing.unit * 12,
    margin: `0 ${theme.spacing.unit * 2}px`,
    backgroundColor: "#00de94",
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#00c281",
    },
    textTransform: "none",
  },

  [theme.breakpoints.down("md")]: {
    button: {
      margin: `0`,
    },
  },
});
const loginText = "Log In";
const singUpText = "Sign Up";
const sellTicketsText = "Sell Tickets";

const renderLoginBtn = ({ loginButton }) => {
  return (
    <Button className={loginButton} href="/login" variant="extendedFab">
      {loginText}
    </Button>
  );
};

const renderRegisterBtn = () => {
  return (
    <Button
      href="#text-buttons"
      style={{ textTransform: "none", color: "#000000" }}
    >
      {singUpText}
    </Button>
  );
};

const desktopNav = ({ classes, isAuthenticated, currUser, handleLogout }) => {
  return (
    <div
      className={
        window.screen.width < 962
          ? classes.navContainerMobile
          : classes.navContainer
      }
    >
      <nav className={classes.nav} position="relative">
        <div className={classes.logoContainer}>
          <a href="/">
            <img
              src={Logo}
              alt="FansFirst Logo"
              height="32"
              className={classes.logo}
            />
          </a>
        </div>
        <div className={classes.linksContainer}>
          <div className={classes.navLinks}>
            <div style={{ borderRight: "1px solid #ddd" }} />
            {/*isAuthenticated && currUser ? "" : renderRegisterBtn()*/}
            {isAuthenticated && currUser ? (
              <AccountMenu currUser={currUser} handleLogout={handleLogout} />
            ) : (
              <Button
                className={classes.sellButton}
                href="/sell"
                variant="extendedFab"
              >
                {sellTicketsText}
              </Button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default withStyles(styles)(desktopNav);
