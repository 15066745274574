import React, { Component } from "react";
import { API } from "aws-amplify";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import _ from "lodash";
import moment from "moment-timezone";
import CancelOkPopup from "../components/shared/CancelOkPopup";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Typography from "@material-ui/core/Typography";
import { withStyles, Button } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { renderSeatNumbers, renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { calculateGST } from "../libs/shared/gst-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getTeamName } from "../libs/shared/team-helper";

/* import DeleteIcon from "../images/icons/bin-with-lid.png"; */

const moneyMask = createNumberMask({
  prefix: "$",
  suffix: "",
  integerLimit: 8,
  decimalLimit: 2,
  allowDecimal: true,
  allowLeadingZeroes: false,
});

const qtyMask = createNumberMask({
  prefix: "",
  suffix: "",
  integerLimit: 2,
  allowDecimal: false,
  allowLeadingZeroes: true,
});

const seatNumberMask = createNumberMask({
  prefix: "",
  suffix: "",
  integerLimit: 5,
  allowDecimal: false,
  allowLeadingZeroes: true,
});

const styles = (theme) => ({
  deleteButton: {
    minWidth: "46px",
    minHeight: "46px",
    border: "1px solid #FF4D4B",
    boxSizing: "border-box",
  },
  editButton: {
    minWidth: "46px",
    minHeight: "46px",
    border: "1px solid #00A0FF",
    boxSizing: "border-box",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    //fontSize: "14px"
  },
  paper: {
    padding: "1.5rem",
    maxHeight: "60vh",
    overflow: "auto",
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  priceInput: {
    padding: "0.75rem",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    fontFamily: "Nunito Sans",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  priceInputError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    fontFamily: "Nunito Sans",
    textAlign: "center",
  },
  priceInputWarning: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    border: "solid 1px #ffc600",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    fontFamily: "Nunito Sans",
    textAlign: "center",
  },
  formControl: {
    marginTop: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  formControlLabel: {
    fontSize: ".875rem",
  },
});

class ActiveSeatsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seats: null,
      editedSeats: null,
      isDeleting: false,
      seatArchiving: "",
      isEditPriceOpen: false,
      isDeleteSeatOpen: true,
      noOfSeats: "-",
      price: 0,
      isValidNoOfSeats: true,
      isValidSeatNumber: true,
      isValidPrice: true,
      editingSeat: null,
      deletingSeat: null,
      isSubmitting: false,
      sellOption: 2,
      editing: false,
      changeLogs: [],
      anyInvalidNoOfSeats: false,
      anyInvalidPrices: false,
      priceWarning: false,
      ignoreWarning: false,
      openPriceWarning: false,
    };

    this.archiveSeat = this.archiveSeat.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.onDeleteSeatClose = this.onDeleteSeatClose.bind(this);
    /* this.renderDialog = this.renderDialog.bind(this); */
    this.handleChange = this.handleChange.bind(this);
    this.saveUpdates = this.saveUpdates.bind(this);
    this.warningOnCancel = this.warningOnCancel.bind(this);
    this.warningOnOk = this.warningOnOk.bind(this);
    /* this.handleSellOptionChange = this.handleSellOptionChange.bind(this); */
    this.onSeatArchived = props.onSeatArchived;
    this.updateActiveSeats = props.updateActiveSeats;
  }

  componentWillMount() {
    const { seats, editing } = this.props;
    this.setState({
      seats,
      editing,
      editedSeats: _.cloneDeep(seats),
    });
  }
  warningOnOk = () => {
    this.setState(
      {
        openPriceWarning: false,
      },
      () => {
        this.props.makeMoreChanges();
      }
    );
  };

  warningOnCancel() {
    this.setState(
      {
        openPriceWarning: false,
        ignoreWarning: true, //
      },
      () => {
        this.props.makeMoreChanges();
        const {
          anyInvalidNoOfSeats,
          anyInvalidPrices,
          editedSeats,
          changeLogs,
        } = this.state;
        this.saveUpdates().then((saved) => {
          if (!anyInvalidNoOfSeats && !anyInvalidPrices) {
            const filteredSeats = editedSeats.filter((seat) =>
              changeLogs.some((log) => log.listingId === seat.seatId)
                ? changeLogs.find((log) => log.listingId === seat.seatId)
                    .delete !== true
                : true
            );
            this.setState({
              editing: false,
              seats: filteredSeats,
              changeLogs: [],
            });
            this.props.updateSeats(filteredSeats);
          }
        });
      }
    );
  }

  async saveUpdates() {
    const {
      anyInvalidNoOfSeats,
      anyInvalidPrices,
      openPriceWarning,
    } = this.state;
    if (openPriceWarning) {
      return false;
    }

    if ((anyInvalidNoOfSeats || anyInvalidPrices) & !this.state.isSubmitting) {
      alert(
        "Invalid changes made. Ticket price and quantity must be greater than 0."
      );
      return false;
    } else {
      await API.post("v2", "marketplace/listings/batchUpdate", {
        body: this.state.changeLogs,
      });
      this.setState({
        ignoreWarning: false,
      });
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.seats !== prevProps.seats ||
      this.props.editing !== prevProps.editing ||
      this.props.submitting !== prevProps.submitting
    ) {
      const { seats, editing, submitting } = this.props;
      const {
        anyInvalidNoOfSeats,
        anyInvalidPrices,
        openPriceWarning,
        editedSeats,
        changeLogs,
      } = this.state;
      if (submitting && changeLogs.length > 0 && !openPriceWarning) {
        const priceDrops = changeLogs.filter(
          (change) =>
            change.changes.price <
            0.75 * seats.find((seat) => seat.seatId === change.listingId).price
        );
        if (priceDrops.length > 0 && !this.state.ignoreWarning) {
          this.setState({
            openPriceWarning: true,
          });
        } else {
          this.saveUpdates().then((saved) => {
            if (!anyInvalidNoOfSeats && !anyInvalidPrices) {
              const filteredSeats = editedSeats.filter((seat) =>
                changeLogs.some((log) => log.listingId === seat.seatId)
                  ? changeLogs.find((log) => log.listingId === seat.seatId)
                      .delete !== true
                  : true
              );
              this.setState({
                editing: false,
                seats: filteredSeats,
                changeLogs: [],
              });
              this.props.updateSeats(filteredSeats);
            }
          });
        }
      } else if (!submitting && changeLogs.length > 0 && !openPriceWarning) {
        // Changes made and discarded
        this.setState({
          seats,
          editing,
          editedSeats: _.cloneDeep(seats),
          changeLogs: [],
        });
      } else {
        this.setState({
          seats,
          editing,
          editedSeats: _.cloneDeep(seats),
          changeLogs: [],
        });
      }
    } else {
      /* console.log({
        componenetDidUpdateEnd: this.state,
        props: this.props,
        prevProps,
      }); */
    }
  }

  async archiveSeat(game, id) {
    const { currUser } = this.props;
    this.setState({
      isArchiving: true,
      seatArchiving: id,
    });

    try {
      await API.post(
        "v2",
        `marketplace/listings/archive?eventId=${game}&listingId=${id}`,
        { headers: { email: currUser.email } }
      );
      // delete seat from this.state.seats
      const updatedSeats = this.state.seats.filter((s) => s.seatId !== id);

      this.setState({
        isDeleteSeatOpen: false,
        seats: [...updatedSeats],
        isArchiving: false,
        seatArchiving: "",
        isLoading: false,
      });
      this.onSeatArchived(); // Call the callback to refresh listings
      this.updateActiveSeats(updatedSeats);
    } catch (e) {
      this.setState({
        isArchiving: false,
        seatArchiving: "",
        isLoading: false,
      });

      console.error(e);
    }
  }

  onClose() {
    this.setState({
      isEditPriceOpen: false,
      editingSeat: null,
      isEditSellOptionFormOpen: false,
      sellOption: 2,
    });
  }

  onDeleteSeatClose() {
    this.setState({ isDeleteSeatOpen: false, deletingSeat: null });
  }

  async onOpen(seat) {
    this.setState({ isEditPriceOpen: true, editingSeat: seat });
  }

  openChageSellOptionForm(seat) {
    this.setState({
      isEditSellOptionFormOpen: true,
      editingSeat: seat,
      sellOption: seat.sellOption ? seat.sellOption : 2,
    });
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  getGameName(seat) {
    if (seat.gameDetails.isTbd || seat.gameDetails.isPlayoffs) {
      return seat.gameDetails.longName;
    }
  
    const team1 = seat.gameDetails.opponent;
    const team2 = getTeamName(seat.gameDetails.homeTeamSlug);
  
    return `${team1} vs. ${team2 ? team2 : '-'}`;
  }

  renderSellOptions() {
    const { sellOption, editingSeat } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            component="legend"
            style={{ marginBottom: ".5rem", fontSize: ".875rem" }}
          >
            How do you want to sell your tickets?
          </FormLabel>
          <RadioGroup
            aria-label="Sell Option"
            name="sellOption"
            className={classes.group}
            value={sellOption}
            onChange={(e) =>
              this.setState({ sellOption: parseInt(e.target.value) })
            }
          >
            <FormControlLabel
              value={2}
              className={classes.formControlLabel}
              control={<Radio />}
              label="Sell any, but don't leave a single ticket"
              disabled={editingSeat.noOfSeats <= 2}
            />
            <FormControlLabel
              value={3}
              className={classes.formControlLabel}
              control={<Radio />}
              label="Sell all (keep my tickets together as a set)"
              disabled={editingSeat.noOfSeats <= 2}
            />
            <FormControlLabel
              value={1}
              className={classes.formControlLabel}
              control={<Radio />}
              label="Sell any, even if it leaves a single remaining"
              disabled={editingSeat.noOfSeats <= 2}
            />
            <FormControlLabel
              value={4}
              className={classes.formControlLabel}
              control={<Radio />}
              label="Sell in pairs"
              disabled={editingSeat.noOfSeats <= 2}
            />
          </RadioGroup>
        </FormControl>
        {editingSeat.noOfSeats > 2 && this.state.sellOption === 2 ? (
          <Typography
            variant="body2"
            style={{ marginTop: "1rem" }}
            color="secondary"
          >
            Note: Buyers can purchase any number of seats that does not leave a
            single seat remaining. Your seats will not be guaranteed to sell all
            at once.{" "}
            {sellOption !== 1
              ? "You will never be left with a single seat."
              : ""}
          </Typography>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  renderSellOptionForm() {
    const { isEditSellOptionFormOpen, editingSeat, isSubmitting } = this.state;

    return (
      isEditSellOptionFormOpen &&
      editingSeat && (
        <Dialog
          open={isEditSellOptionFormOpen}
          onClose={this.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Edit Sell Option</DialogTitle>
          <DialogContent>
            <Typography variant="caption">Game:</Typography>
            <Typography variant="body2">{editingSeat.gameName}</Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: "1rem",
              }}
            >
              {editingSeat.zone} {editingSeat.zoneNo} Row {editingSeat.row}
            </Typography>

            {this.renderSellOptions()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={this.handleSellOptionChange}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }

  async openDelete(seat) {
    this.setState({ isDeleteSeatOpen: true, deletingSeat: seat });
  }

  handleChange({ target }, index, seat, property) {
    // if this.state.changeLogs contains an object where the listingId=seat.seatId,
    // we should update that object in place. Otherwise, we should create a new object
    var changeLog = null;
    const { editedSeats } = this.state;
    const editedSeat = editedSeats.find((edit) => edit.seatId === seat.seatId);
    const changeLogs = this.state.changeLogs;
    changeLog = changeLogs.find(
      (changeLog) => changeLog.listingId === seat.seatId
    );
    if (!changeLog) {
      changeLog = {
        listingId: seat.seatId,
        eventId: seat.game,
        changes: {},
      };
    }
    if (property === "noOfSeats") {
      changeLog.changes["quantity"] = parseInt(target.value) + seat.seatsSold;
      changeLog.changes["seatNumberStart"] =
        Number(target.value) === 0 ? 0 : editedSeat.seatNumberStart;
      changeLog.changes["seatNumberEnd"] =
        Number(target.value) > 0 && Number(editedSeat.seatNumberStart) > 0
          ? Number(target.value) +
            Number(target.value) -
            1
          : 0;
    } else if (property === "seatNumberStart") {
      changeLog.changes["seatNumberStart"] =
        Number(target.value) === 0 ? 0 : target.value;
      changeLog.changes["seatNumberEnd"] =
        Number(target.value) > 0
          ? Number(editedSeat.noOfSeats) - Number(editedSeat.seatsSold) + Number(target.value) - 1
          : 0;
    } else if (property === "sellOption") {
      changeLog.changes["sellOption"] = target.value;
    } else {
      changeLog.changes[property] = parseFloat(
        target.value.replace(/[$,]/g, "")
      );
    }
    // if changeLogs contained a changeLog with the same listingId,
    // we should update that object in place.
    // Otherwise, we should append a new object to changeLogs
    if (changeLogs.find((changeLog) => changeLog.listingId === seat.seatId)) {
      changeLogs.splice(
        changeLogs.findIndex(
          (changeLog) => changeLog.listingId === seat.seatId
        ),
        1,
        changeLog
      );
    } else {
      changeLogs.push(changeLog);
    }
    this.setState({ changeLogs });

    if (property === "sellOption") {
      var newEditedSeats = editedSeats.map((editSeat) => {
        if (editSeat.seatId === seat.seatId) {
          return { ...editSeat, [property]: target.value };
        }
        return editSeat;
      });
    } else if (property === "seatNumberStart") {
      var newEditedSeats = editedSeats.map((editSeat) => {
        if (editSeat.seatId === seat.seatId) {
          return {
            ...editSeat,
            [property]: Number(target.value) === 0 ? 0 : target.value,
            seatNumberEnd:
              Number(target.value) > 0
                ? Number(editSeat.noOfSeats) - Number(editSeat.seatsSold) + Number(target.value) - 1
                : 0,
          };
        }
        return editSeat;
      });
    } else if (property === "noOfSeats") {
      var newEditedSeats = editedSeats.map((editSeat) => {
        if (editSeat.seatId === seat.seatId) {
          var defaultSellOption = editedSeats[index].sellOption;
          const quantity = parseInt(target.value);
          if (quantity === 0 || isNaN(quantity)) {
            this.setState({ anyInvalidNoOfSeats: true });
          } else {
            this.setState({ anyInvalidNoOfSeats: false });
          }
          if (
            parseInt(target.value) === 1 &&
            editedSeats[index].sellOption === 2
          ) {
            // Prevent "sell any with no remainder" for odd number of seats
            defaultSellOption = 1;
          } else if (
            parseInt(target.value) % 2 === 1 &&
            editedSeats[index].sellOption === 4
          ) {
            // Prevent "sell in pairs" if only selling one seat
            defaultSellOption = 1;
          }
          return {
            ...editSeat,
            [property]: parseInt(target.value),
            noOfSeats:
              Number(target.value) + Number(editSeat.seatsSold),
            seatNumberStart:
              Number(target.value) === 0 ? 0 : editSeat.seatNumberStart,
            seatNumberEnd:
              Number(target.value) > 0 && Number(editSeat.seatNumberStart) > 0
                ? Number(target.value) +
                  Number(editSeat.seatNumberStart) -
                  1
                : 0,
            sellOption: defaultSellOption,
          };
        }
        return editSeat;
      });
    } else if (property === "price") {
      var newEditedSeats = editedSeats.map((editSeat) => {
        if (editSeat.seatId === seat.seatId) {
          // remove all $ and , chars using regex
          const price = parseFloat(target.value.replace(/[$,]/g, ""));
          if (price === 0 || isNaN(price)) {
            this.setState({ anyInvalidPrices: true });
          } else {
            this.setState({ anyInvalidPrices: false });
          }
          return {
            ...editSeat,
            [property]: price,
          };
        }
        return editSeat;
      });
    }
    this.setState({
      editedSeats: newEditedSeats,
    });
  }

  handleDeleteToggle({ target }, seat) {
    var changeLog = null;
    const changeLogs = this.state.changeLogs;
    changeLog = changeLogs.find(
      (changeLog) => changeLog.listingId === seat.seatId
    );
    if (!changeLog) {
      changeLog = {
        listingId: seat.seatId,
        eventId: seat.game,
        changes: {},
      };
    }
    changeLog["delete"] = target.checked;
    if (changeLogs.find((changeLog) => changeLog.listingId === seat.seatId)) {
      changeLogs.splice(
        changeLogs.findIndex(
          (changeLog) => changeLog.listingId === seat.seatId
        ),
        1,
        changeLog
      );
    } else {
      changeLogs.push(changeLog);
    }
    this.setState({ changeLogs });
  }

  handleCheckboxToggle({ target }, seat) {
    var changeLog = null;
    const changeLogs = this.state.changeLogs;
    changeLog = changeLogs.find(
      (changeLog) => changeLog.listingId === seat.seatId
    );
    const { editedSeats } = this.state;
    if (!changeLog) {
      changeLog = {
        listingId: seat.seatId,
        eventId: seat.game,
        changes: {},
      };
    }

    changeLog.changes[target.name] = target.checked;
    if (changeLogs.find((changeLog) => changeLog.listingId === seat.seatId)) {
      changeLogs.splice(
        changeLogs.findIndex(
          (changeLog) => changeLog.listingId === seat.seatId
        ),
        1,
        changeLog
      );
    } else {
      changeLogs.push(changeLog);
    }
    var newEditedSeats = editedSeats.map((editSeat) => {
      if (editSeat.seatId === seat.seatId) {
        return { ...editSeat, [target.name]: target.checked };
      }
      return editSeat;
    });
    /* console.log({ newEditedSeats }); */
    this.setState({ changeLogs, editedSeats: newEditedSeats });
  }

  renderDeleteDialog() {
    const { isDeleteSeatOpen, deletingSeat, isArchiving } = this.state;

    return (
      deletingSeat && (
        <Dialog
          open={isDeleteSeatOpen}
          onClose={this.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Delete Seat</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                marginBottom: "1rem",
              }}
            >
              Are you sure you want to delete this listing?
              <Typography style={{ marginTop: "1rem" }} component="span">
                {deletingSeat.gameName}{" "}
              </Typography>
              <Typography component="span">
                {deletingSeat.zone} {deletingSeat.zoneNo} Row {deletingSeat.row}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={isArchiving} onClick={this.onDeleteSeatClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isArchiving}
              onClick={() =>
                this.archiveSeat(deletingSeat.game, deletingSeat.seatId)
              }
            >
              {!isArchiving ? "Yes - Delete" : "Deleting Seat..."}
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }

  renderSellOptionSelect(index, seat, sellOption) {
    const { editedSeats } = this.state;
    const seatId = seat.seatId;
    /* const sellOption = editedSeats[index].sellOption; */
    const noOfSeats = editedSeats[index].noOfSeats - seat.seatsSold;
    return (
      <Select
        value={sellOption}
        onChange={(e) => this.handleChange(e, index, seat, "sellOption")}
        style={{
          fontSize: 12,
          maxWidth: 175,
          border: "1px solid #00A0FF",
          borderRadius: "5px",
          paddingLeft: "2%",
        }}
      >
        {noOfSeats != 1 ? (
          <MenuItem value={2}>
            <Typography
              style={{
                fontWeight: "400",
                color: "#5F666F",
                fontSize: "12px",

                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Sell any, but don't leave a single ticket
            </Typography>
          </MenuItem>
        ) : null}

        <MenuItem value={3}>
          <Typography
            style={{
              fontWeight: "400",
              color: "#5F666F",
              fontSize: "12px",

              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            Sell all (keep my tickets together as a set)
          </Typography>
        </MenuItem>

        <MenuItem value={1}>
          <Typography
            style={{
              fontWeight: "400",
              color: "#5F666F",
              fontSize: "12px",

              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            Sell any, even if it leaves a single remaining
          </Typography>
        </MenuItem>
        {noOfSeats % 2 == 0 ? (
          <MenuItem value={4}>
            <Typography
              style={{
                fontWeight: "400",
                color: "#5F666F",
                fontSize: "12px",

                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Sell in pairs
            </Typography>
          </MenuItem>
        ) : null}
      </Select>
    );
  }

  getSellOption(value) {
    switch (value) {
      case 1:
        return "Sell any, even if it leaves a single remaining";
      case 2:
        return "Sell any, but don't leave a single ticket";
      case 3:
        return "Sell all (keep my tickets together as a set)";
      case 4:
        return "Sell in pairs";
    }
  }

  getInputClass(value, oldValue) {
    const { classes } = this.props;
    if (value === 0 || isNaN(value)) {
      return classes.priceInputError;
    } else if (value < oldValue * 0.75) {
      return classes.priceInputWarning;
    } else {
      return classes.priceInput;
    }
  }

  renderTable(seats) {
    const { isSoldOut, classes } = this.props;
    const { editing, editedSeats } = this.state;
    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              <TableCell style={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Game
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Details
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Price (CAD){" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Numbers{" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Available{" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Sold{" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Remaining Proceeds
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Sell Option
                </Typography>
              </TableCell>
              {editing && (
                <TableCell
                  padding="checkbox"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: "#5F666F",
                      fontSize: "14px",
                    }}
                  >
                    Delete?
                  </Typography>
                </TableCell>
              )}
              {!editing && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {seats.map((seat, index) => {
              const { gameDetails: game } = seat;
              const sellOption = editedSeats[index].sellOption;
              const defaultSeatNumbers = {
                start:
                  typeof this.state.editedSeats[index].seatNumberStart ===
                  "undefined"
                    ? seat.seatNumberStart
                    : "",
                end:
                  typeof this.state.editedSeats[index].seatNumberEnd ===
                  "undefined"
                    ? seat.seatNumberEnd
                    : "",
              };
              const slug = `${game.homeTeamSlug}-tickets`;

              return (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: "10px 24px" }}
                  >
                    <Typography
                      className={classes.titleText}
                      style={{ color: "#00A0FF", textTransform: "uppercase" }}
                    >
                      {seat.gameDetails.isTbd
                        ? "TBD"
                        : this.getGameDate(seat.gameDetails)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {seat.gameDetails.isTbd
                        ? null
                        : this.getGameTime(seat.gameDetails)}
                    </Typography>
                    <a href={`/seats/${slug}/${game.id}`}>
                      <Typography className={classes.titleText}>
                        {this.getGameName(seat)}
                      </Typography>
                    </a>
                  </TableCell>

                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>
                    {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                    </Typography>
                    <Typography className={classes.subTitleText}>
                      Row {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}{" "}
                    </Typography>
                    {editing ? (
                      <FormControlLabel
                        style={{ height: "20px" }}
                        control={
                          <Checkbox
                            textAlign="center"
                            name="isAisleSeat"
                            onChange={(e) =>
                              this.handleCheckboxToggle(e, seat)
                            }
                            defaultChecked={editedSeats[index].isAisleSeat}
                            color="rgb(33, 150, 243)"
                            height="10px"
                            style={{ height: "20px" }}
                          ></Checkbox>
                        }
                        label={
                          <Typography
                            style={{
                              display: "inline-block",
                              color: "rgb(33, 150, 243)",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.subTitleText}
                          >
                            Aisle Seats
                          </Typography>
                        }
                      />
                    ) : null}
                    {editedSeats[index].isAisleSeat &&
                    parseInt(editedSeats[index].seatsSold) === 0 &&
                    !editing ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Aisle Seats
                      </Typography>
                    ) : null}
                    {editing ? (
                      <FormControlLabel
                        style={{ height: "20px" }}
                        control={
                          <Checkbox
                            textAlign="center"
                            name="isWheelchair"
                            onChange={(e) =>
                              this.handleCheckboxToggle(e, seat)
                            }
                            defaultChecked={editedSeats[index].isWheelchair}
                            color="rgb(33, 150, 243)"
                            height="10px"
                            style={{ height: "20px" }}
                          ></Checkbox>
                        }
                        label={
                          <Typography
                            style={{
                              display: "inline-block",
                              color: "rgb(33, 150, 243)",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.subTitleText}
                          >
                            Wheelchair
                          </Typography>
                        }
                      />
                    ) : null}
                    {editedSeats[index].isWheelchair && !editing ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Wheelchair
                      </Typography>
                    ) : null}
                    {editing ? (
                      <FormControlLabel
                        style={{ height: "20px" }}
                        control={
                          <Checkbox
                            textAlign="center"
                            name="isObstructedView"
                            onChange={(e) =>
                              this.handleCheckboxToggle(e, seat)
                            }
                            defaultChecked={editedSeats[index].isObstructedView}
                            color="rgb(33, 150, 243)"
                            height="10px"
                            style={{ height: "20px" }}
                          ></Checkbox>
                        }
                        label={
                          <Typography
                            style={{
                              display: "inline-block",
                              color: "rgb(33, 150, 243)",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.subTitleText}
                          >
                            Obstructed View
                          </Typography>
                        }
                      />
                    ) : null}
                    {editedSeats[index].isObstructedView && !editing ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Obstructed View
                      </Typography>
                    ) : null}
                  </TableCell>

                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {editing ? (
                      <FormControl
                        error={!this.state.isValidPrice}
                        fullWidth={false}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MaskedInput
                          mask={moneyMask}
                          defaultValue={seat.price.toFixed(2)}
                          className={this.getInputClass(
                            editedSeats[index].price,
                            seat.price
                          )}
                          style={{ width: "100px" }}
                          placeholder="Enter Price"
                          guide={true}
                          onChange={(e) =>
                            this.handleChange(e, index, seat, "price")
                          }
                        />
                      </FormControl>
                    ) : (
                      <Typography className={classes.subTitleText}>
                        ${seat.price.toFixed(2)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {editing ? (
                      <FormControl
                        error={!this.state.isValidSeatNumber}
                        fullWidth={false}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <MaskedInput
                          mask={seatNumberMask}
                          className={classes.priceInput}
                          style={{ width: "70px", marginRight: "8px" }}
                          placeholder="Start"
                          value={
                            this.state.editedSeats &&
                            this.state.editedSeats[index].seatNumberStart
                              ? this.state.editedSeats[index].seatNumberStart
                              : defaultSeatNumbers.start
                          }
                          onChange={(e) =>
                            this.handleChange(e, index, seat, "seatNumberStart")
                          }
                        />
                        -
                        <MaskedInput
                          mask={seatNumberMask}
                          className={classes.priceInput}
                          style={{ width: "70px", marginLeft: "8px" }}
                          value={
                            this.state.editedSeats &&
                            this.state.editedSeats[index].seatNumberStart
                              ? Number(this.state.editedSeats[index].seatNumberStart) + Number(this.state.editedSeats[index].noOfSeats) - Number(this.state.editedSeats[index].seatsSold) -1 
                              : defaultSeatNumbers.end
                          }
                          placeholder="End"
                          disabled="disabled"
                        />
                      </FormControl>
                    ) : (
                      <Typography className={classes.subTitleText}>
                        {renderSeatNumbers(seat, false, "-", true)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {editing ? (
                      <FormControl
                        error={!this.state.isValidNoOfSeats}
                        fullWidth={false}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MaskedInput
                          mask={qtyMask}
                          defaultValue={seat.noOfSeats - seat.seatsSold}
                          className={this.getInputClass(
                            editedSeats[index].noOfSeats - seat.seatsSold
                          )}
                          style={{ width: "75px" }}
                          placeholder="Enter Quantity"
                          /* guide={true} */
                          onChange={(e) =>
                            this.handleChange(e, index, seat, "noOfSeats")
                          }
                        />
                      </FormControl>
                    ) : (
                      <Typography className={classes.subTitleText}>
                        {seat.noOfSeats - seat.seatsSold}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {!seat.isArchived ? (
                      <Typography className={classes.subTitleText}>
                        {seat.seatsSold}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {!seat.isArchived ? (
                      <Typography className={classes.subTitleText}>
                        $
                        {calculateGST(
                          seat.noOfSeats - seat.seatsSold,
                          seat.price
                        ).toFixed(2)}
                        {/* {(
                          (seat.noOfSeats - seat.seatsSold) *
                          seat.price *
                          0.91
                        ).toFixed(2)} */}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </TableCell>

                  {!seat.isHistory && !seat.isSold && !seat.isArchived && (
                    <TableCell padding="none" style={{ textAlign: "center" }}>
                      {editing ? (
                        this.renderSellOptionSelect(index, seat, sellOption)
                      ) : (
                        <Typography
                          className={classes.subTitleText}
                          style={{ textAlign: "center" }}
                        >
                          {this.getSellOption(seat.sellOption)}
                        </Typography>
                      )}
                    </TableCell>
                  )}

                  {!seat.isHistory && !editing && (
                    <TableCell padding="checkbox">
                      {!seat.isArchived && !seat.isSoldOut && (
                        <Button
                          onClick={() => this.openDelete(seat)}
                          variant="text"
                          color="default"
                          size="small"
                          className={classes.deleteButton}
                        >
                          <DeleteIcon color="error"></DeleteIcon>
                        </Button>
                      )}
                    </TableCell>
                  )}

                  {!seat.isHistory && editing && (
                    <TableCell style={{ textAlign: "center" }}>
                      {!seat.isArchived && !seat.isSoldOut && (
                        <Checkbox
                          textAlign="center"
                          onChange={(e) => this.handleDeleteToggle(e, seat)}
                        ></Checkbox>
                      )}
                    </TableCell>
                  )}

                  {isSoldOut && seat.isPaid && (
                    <React.Fragment>
                      <TableCell
                        padding="checkbox"
                        style={{
                          width: 100,
                        }}
                      >
                        {moment(seat.datePaid)
                          .utcOffset(-6)
                          .format("MMM DD, YYYY")}{" "}
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        style={{
                          width: 120,
                        }}
                      >
                        {seat.paymentReceiptNo ? seat.paymentReceiptNo : "-"}
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          {isSoldOut && (
            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell padding="checkbox" numeric>
                  Total:{" "}
                  <strong style={{ color: "#4caf50" }}>
                    CAD${" "}
                    {_.chain(seats)
                      .map((seat) => {
                        return {
                          ...seat,
                          ...{
                            // totalProceeds: seat.noOfSeats * seat.price * 0.91,
                            totalProceeds: calculateGST(
                              seat.noOfSeats,
                              seat.price
                            ),
                          },
                        };
                      })
                      .sumBy("totalProceeds")
                      .value()
                      .toFixed(2)}
                  </strong>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>
    );
  }

  render() {
    const { isSoldOut, isHistory } = this.props;
    const { seats } = this.state;

    return (
      <React.Fragment>
        {/* <Paper className={classes.paper}> */}
        {seats.length > 0 && this.renderTable(seats)}
        {seats.length === 0 && (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            {!isSoldOut && !isHistory && "No Active Listings"}
            {isSoldOut && !isHistory && "No Sold Out Listings"}
            {isHistory && "No Past Listings"}
          </Typography>
        )}
        {/* </Paper> */}
        {/* {this.renderDialog()} */}
        {this.renderSellOptionForm()}
        {this.renderDeleteDialog()}
        <CancelOkPopup
          isActive={this.state.openPriceWarning}
          onOk={this.warningOnOk}
          onCancel={() => this.warningOnCancel()}
          title={"Warning"}
          message={
            "You have dropped at least one price by over 25%. Are you sure you want to proceed?"
          }
          okLabel={"Cancel"}
          cancelLabel={"Proceed"}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ActiveSeatsTable);
