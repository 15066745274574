import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, IconButton, SwipeableDrawer, withStyles, Typography } from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/HomeRounded";
import TicketIcon from "@material-ui/icons/ConfirmationNumberRounded";
import Divider from "@material-ui/core/Divider";
import Logo from "../../images/logo.png";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const styles = (theme) => ({
  navContainer: {
    boxShadow: "0 4px 6px 0 rgba(0,0,0,0.03)",
    position: "fixed",
    width: "100%",
    backgroundColor: "#fafafa",
    zIndex: "13",
  },
  nav: {
    display: "flex",
  },
  menuContainer: {
    maxWidth: theme.spacing.unit * 6,
    flex: 1,
    position: "relative",
    top: "4px",
  },
  logoContainer: {
    paddingTop: `${theme.spacing.unit}px`,
    position: "relative",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    // height: theme.spacing.unit * 4,
    height: "41px",
    width: "170px",
  },
  homeIcon: {
    color: "#fff",
  },
});

const mobileNav = ({ classes, isAuthenticated, currUser, handleLogout }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleAccountDrawer = () => {
    setIsAccountDrawerOpen(!isAccountDrawerOpen);
  };

  const renderDrawer = () => {
    return (
      <SwipeableDrawer open={isDrawerOpen} onClose={toggleDrawer}>
        <div tabIndex={0} role="button" onClick={() => toggleDrawer()} onKeyDown={() => toggleDrawer()} style={{ backgroundColor: "#2faaf3", width: "300px", height: "100%" }}>
          <div className={classes.list}>
            <List style={{ backgroundColor: "#2faaf3"}}>
              <ListItem button>
                <ListItemIcon>
                  <CloseRoundedIcon className={classes.homeIcon} />
                </ListItemIcon>
              </ListItem>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Home
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircle className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {isAuthenticated ? "Listings" : "Sign In"}
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/sell" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Sell Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/calgary-flames/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Flames Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/winnipeg-jets/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Jets Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/edmonton-oilers/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Oilers Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/vancouver-canucks/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Canucks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/toronto-maple-leafs/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Maple Leafs Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/ottawa-senators/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Senators Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/toronto-raptors/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Raptors Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/toronto-blue-jays/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Blue Jays Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/bc-lions/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Lions Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/edmonton-elks/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Elks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/calgary-stampeders/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Stampeders Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/winnipeg-blue-bombers/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Blue Bombers Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/toronto-argonauts/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Argonauts Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/ottawa-redblacks/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Redblacks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/saskatchewan-roughriders/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Roughriders Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/hamilton-tigercats/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Tiger-Cats Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                to="/grey-cup/games"
                style={{ textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon  style={{color: "#FFFFFF",}}  className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Grey Cup Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/calgary-wranglers/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Wranglers Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/abbotsford-canucks/games" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon className={classes.homeIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Buy Abbotsford Canucks Tickets
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Divider />
              {/* <Link to="/sth" style={{ textDecoration: 'none' }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon />
                  </ListItemIcon>
                  <ListItemText primary="Season Ticket Holders Forum" />
                </ListItem>
              </Link> */}
              <Link to="/legal" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Legal
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/why-us" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        About FansFirst
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/faqs" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        FAQs
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  };

  const renderAccountDrawer = () => {
    return (
      <SwipeableDrawer anchor="right" open={isAccountDrawerOpen} onClose={toggleAccountDrawer}>
        <div tabIndex={0} role="button" onClick={() => toggleAccountDrawer()} onKeyDown={() => toggleAccountDrawer()}>
          <div className={classes.list}>
            <List>
              <Link to="/listings" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemText primary="Listings" />
                </ListItem>
              </Link>
              <Link to="/account" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemText primary="Account Settings" />
                </ListItem>
              </Link>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  };

  return (
    <div className={classes.navContainer}>
      <nav className={classes.nav}>
        <div className={classes.menuContainer}>
          <IconButton color="primary" aria-label="Menu" onClick={() => toggleDrawer()}>
            <MenuIcon />
          </IconButton>
        </div>
        <div className={classes.logoContainer}>
          <a href="/">
            <img src={Logo} alt="FansFirst Logo" className={classes.logo} />
          </a>
        </div>
        <div className={classes.menuContainer}>
          {isAuthenticated && currUser ? (
            <IconButton color="#00A0FF" aria-label="Menu" onClick={() => toggleAccountDrawer()}>
              <AccountCircle />
            </IconButton>
          ) : null}
        </div>
      </nav>
      {renderDrawer()}
      {renderAccountDrawer()}
    </div>
  );
};

export default withStyles(styles)(mobileNav);
