import React from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const sellRequestWarningPopup = (props) => {
  const {
    onProceed,
    onUpdate,
    open,
    priceWarning,
    singleQuantityWarning,
    largeQuantityWarning,
    quantity,
  } = props;

  const handleProceed = () => {
    onProceed();
  };
  const handleUpdate = () => {
    onUpdate();
  };
  var message = "";
  if (priceWarning && (singleQuantityWarning || largeQuantityWarning)) {
    message += `You entered a price that is 25% or more cheaper than the next cheapest seat and you entered ${quantity} ${
      quantity == 1 ? "seat" : "seats"
    }. Is that correct?`;
  } else if (priceWarning) {
    message +=
      "You entered a price that is 25% or more cheaper than the next cheapest seat, is that correct?";
  } else if (singleQuantityWarning) {
    message += "You entered 1 seat, is that correct?";
  } else if (largeQuantityWarning) {
    message += `You entered ${quantity} seats, is that correct?`;
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm">
      <DialogTitle id="simple-dialog-title">Question?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProceed} color="grey" autoFocus>
          Proceed Anyway
        </Button>
        <Button onClick={handleUpdate} color="primary" autoFocus>
          Update Details
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default sellRequestWarningPopup;
